.video-timeline {
    position: absolute;

    width: 100%;
    top: calc(100% - 65px);

    background-color: rgba(0, 0, 0, 0.91);

    border-radius: 0px 0px 10px 10px;

    display: flex;
    justify-content: space-between;

    &:hover {
        .video-timeline__timeline {
            height: 100px;
        }

        .video-timeline__info {
            opacity: 1;
            height: auto;
        }

        .video-timeline__outer {
            padding-bottom: 65px;
        }
    }
}

.video-timeline__outer {
    max-width: 1000px;
    width: 100%;
    position: relative;

    padding-bottom: 50px;
}

.video-timeline__bar {
    width: 3px;
    height: 120%;

    background-color: gray;

    border-radius: 5px;

    top: 50%;
    transform: translateY(-50%);

    position: absolute;
}

.video-timeline__tooltip {
    min-width: 120px;
}

.video-timeline__info {
    right: 0px;
    height: 0px;
    top: 0px;
    box-sizing: border-box;
    padding: 15px;

    display: flex;

    flex-direction: column;

    transition: .4s;

    opacity: 0;

    gap: 10px;

    color: white;

    p {
        color: rgba(255, 255, 255, 0.7);
        font-size: 12px;
    }

    b {
        color: white;
    }
}

.video-timeline-session__text {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: white;
    gap: 5px;

    font-size: 14px;

    p {
        white-space: nowrap;
    }
}

.video-timeline__line {
    width: 100%;
    height: 10px;

    background-color: #ffffff33;

    transform: translateY(-50%);
    top: 50%;
    border-radius: 10px;

    position: absolute;
}

.video-timeline__timeline {
    width: calc(100% - 270px);
    margin-left: 80px;

    height: 35px;

    transition: 0.4s;

    position: relative;
}

.video-timeline__session {
    background-color: #8dc572;
    height: 70%;

    max-height: 50px;

    transform: translateY(-50%);
    top: 50%;

    border-radius: 10px;

    box-sizing: border-box;

    padding: 5px;

    z-index: 2;

    cursor: pointer;

    display: flex;
    align-items: center;

    p {
        font-size: 12px;
        color: white;
    }
}