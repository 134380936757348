.no-data-error {
    color: #979CA0;
    text-align: center;

    h1 {
        font-size: 50px;
        margin-bottom: 20px;
    }

    b {
        font-size: 20px;
        font-weight: 300;
    }

    p {
        font-size: 14px;
        font-weight: 300;

        margin: 20px 0px;
    }
}