.sidebar-menu {

}

.sidebar-menu__item {
    display: flex;
    border-bottom: 1px solid $border-color;
    padding: 10px 20px 10px 20px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    color: $color-grey;

    @include phone {
        width: $sidebar-width;
    }

    .default-icon{
        margin-right: 12px;
        width: 100%;
    }

    &:hover, &.active {
        cursor: pointer;

        .sidebar-menu__text {
            color: $color-dark-grey;
        }

        .default-icon {
            width: 100%;

            path {
                fill: $color-red-hover !important;

            }
        }

    }

}

.sidebar__col{
    .nav-button__previous {
        margin: 10px 20px 10px 20px;
    }
}

.sidebar-menu__chevron {
    margin-left: auto;
}

.sidebar-menu__text {
    color: $color-grey;
}

