.messages-popup {
    .notifications-popup__head {
        align-items: center;
    }

    .notifications-popup__icon {
        background-color: $color-orange;
    }

    .notifications-popup__links {
        p {
            margin-bottom: 0px;
            color: black;
        }
    }
}