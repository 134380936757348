.utils__row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.utils__rowSpaceBetween {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.utils__gap {
    &--10 {
        gap: 10px;
    }

    &--20 {
        gap: 20px;
    }
}

.utils__flexWrap {
    flex-wrap: wrap;
}

.utils__wrap {
    flex-wrap: wrap;
}

.utils__rowCentered {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;
}

.utils__column {
    display: flex;
    flex-direction: column;
}

.utils__center {
    display: flex;
    justify-content: center;
    align-items: center;
}