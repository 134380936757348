.input-currency__outer {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.input-currency-outer__label {
    font-size: 14px;
    color: #354859;
}

.input-currency-outer__error {
    p {
        font-size: 14px !important;
        color: $color-red !important; 
    }
}

.input-currency {
    display: flex;
    align-items: center;

    gap: 12px;

    box-sizing: border-box;

    padding: 15px 30px;
    border-radius: 20px;

    border: 1px solid #707070;

    color: #61707F;

    background-color: white;

    &:focus {
        background-color: white;
    }

    input {
        border: none;
        outline: none;

        background-color: transparent;

        font-size: 16px;

        width: 100%;

        color: #61707F;
    }
}