.course-module-settings {

}

.module-settings__item {
    padding: 20px;
    border-radius: 10px;

    background-color: white;
    box-sizing: border-box;
}