.editor-image {
    display: flex;
    flex-direction: column;

    @media (min-width: 1600px) {
        flex-direction: row;
    }
}

.editor-image__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 56px;

    margin-bottom: 19.5px;
    margin-top: 20px;

    button {
        max-width: 250px;
        height: 30px;
    }

    a {
        color: #D2DBE2;
        white-space: nowrap;
        margin-left: 20px;
    }

    @include desktop-large {
        margin-top: 0px;

        button {
            margin-right: 20px;
        }
    }
}

.editor-image__content {
    @include desktop {
        box-sizing: border-box;
    }

    .input-outer {
        margin-bottom: 14px;
    }

    label {
        margin-bottom: 7px;
    }

    input {
        height: 30px;
    }
}

.editor-image__img {
    width: 100%;
    height: 200px;

    position: relative;

    margin-right: 25px;
    margin-bottom: 20px;

    div {
        width: 100%;
        height: 26.2px;

        position: absolute;
        bottom: 0px;

        color: #ffffff;
        font-size: 12px;

        backdrop-filter: blur(30px);

        text-align: center;


        @include flex-center;
    }

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    &--empty {
        background: lighten(#cccccc, 17%);
    }

    @media (min-width: 1600px) {
        max-width: 300px;
        margin-top: 0px;
    }
}
