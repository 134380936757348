.chatbox {
    padding: 20px;
    box-sizing: border-box;

    background-color: white;

    width: 516px;
}

.chatbox__head {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.chatbox__chat {
    border-radius: 20px;
    background-color: #F5F8FA;

    padding: 10px;

    box-sizing: border-box;

    margin-top: 35px;

    display: flex;
    flex-direction: column;

    height: calc(100% - 90px);

    gap: 15px;
}

.chatbox__messages {
    height: 100%;

    overflow: auto;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;

    gap: 10px;
    padding-right: 20px;

}

.chatbox-message__outer {
    display: flex;
    flex-direction: column;

    gap: 10px;

    &--sent {
        align-items: flex-end;
    }

    &--received {
        align-items: flex-start;
    }
}

.chatbox-message {
    border-radius: 10px;

    padding: 13px;

    box-sizing: border-box;

    width: fit-content;

    display: flex;
    flex-direction: column;

    max-width: 400px;
        min-width: 200px;
    gap: 10px;

    &--sent {
        background-color: #929BA8;
    }

    &--received {
        background-color: $color-orange;
    }
}

.chatbox-message-task {
    .chatbox-message__sender, .chatbox-message__date {
        align-self: end;
    }
}

.chatbox-message-full {
    @extend .chatbox-message;

    min-height: 100px;
    max-width: 100%;
    width: 100%;

    justify-content: space-between;

    background-color: $color-orange;
}

.chatbox-message__fileName {
    font-size: 12px;
    text-align: center;
    color: white;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chatbox-message__date {
    color: #C6C7D2;
    font-size: 12px;
}

.chatbox-message__message {
    color: white;
    word-break: break-all;
}

.chatbox-message__sender {
    font-size: 12px;
    color: #354859;
}

.chatbox-message__icon {
    display: flex;
    align-self: center;
    width: 50px;
    img {
        width: 100%;
    }
}

.chatbox__close {
    width: 50px;
    height: 50px;

    background-color: #61707F;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    border-radius: 50%;
transition: 0.2s;
    &:hover {
        background-color: darken(#61707F, 20%);
        cursor: pointer;
    }

}

.chatbox-head__text {
    display: flex;
    flex-direction: column;

    gap: 10px;

    width: calc(100% - 100px);
}

.chatbox-head-text__title {
    font-size: 14px;
}

.chatbox-head-text__logged {
    font-size: 12px;
    color: #354859;
}

.chatbox__input {
    border: 1px solid #BEC0CB;
    border-radius: 10px;

    background-color: white;

    padding: 10px;
    box-sizing: border-box;

    gap: 20px;

    display: flex;
    justify-content: space-between;

    textarea {
        width: 100%;

        border: none;
        outline: none;

        font-family: Inter;
    }
}

.chatbox-input__buttons {
    display: flex;
    flex-direction: column;

    gap: 10px;
}
