.container-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;

    gap: 30px;

    .card {
        min-width: unset;
        min-height: unset;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        width: 100%;
        max-width: 400px;

        @media (min-width: 905px) {
            max-width: unset;
        }
    }

    .card__buttons {
        flex-wrap: wrap;
        align-items: center;
        display: flex;
    }

    @media (min-width: 905px) {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
}

.container-index {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.container-index__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}

.container-index__content {
    margin-bottom: 30px;
}

.container-index__pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 15px;

    p {
        font-size: 14px;
        color: #929BA8;
    }
}

.container-table {
    width: 100%;
    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 10px;

    box-sizing: border-box;
    padding: 20px;

    gap: 10px;
}

.container-table__headers {
    display: flex;
    gap: 50px;
}

.container-table__header {

}

.container-table-column__name {
    color: #C6C7D2;
}

.container-table__rows {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.container-table__row {
    display: flex;
    align-items: center;
    gap: 50px;
}

.container-table-row__cell {
    display: flex;
}
