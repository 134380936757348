#alert-container {
    width: 100%;

    position: fixed;
    bottom: 0px;

    left: 50%;

    transform: translateX(-50%);

    z-index: 2999;

    @include desktop {
        max-width: 600px;
    }
}

.alert-item {
    width: 100%;

    background-color: red;

    border-radius: 20px 20px 0px 0px;

    display: flex;
    flex-direction: row;

    animation-duration: 0.4s;

    &--big {
        margin-top: -100%;
        height: 500px;
        border-radius: 20px;
        width: 150%;
        margin-left: -15%;
    }

}

.alert-item__head {
    padding: 0px 56px;

    font-size: 20px;
    color: #FFFFFF;

    border-right: 1px solid #FFFFFF;

    text-transform: capitalize;

    @include flex-center;
}

.alert-item__inner {
    width: 100%;

    position: relative;

    padding: 32px 50px;

    font-size: 14px;

    display: flex;
    flex-direction: column;

    justify-content: center;

    b {
        color: #FFFFFF;
    }

    p {
        margin-top: 9px;
    }

    span {
        color: #FFFFFF;
        font-weight: 600;
    }

    @include desktop {
        white-space: nowrap;
    }
}

#alert-item__exit {
    position: absolute;
    top: 20px;
    right: 20px;

    cursor: pointer;
}

.alert-item--success {
    background-color: #00C8C3;
}

.alert-item--error {
    background-color: #EA1557;
}
