.select-dropdown {
    border: 1px solid #C6C7D1;

    width: 267px;
    height: 45px;

    transition: 0.5s height linear;

    background-color: white;

    font-weight: 300;

    border-radius: 20px;
}

.select-dropdown--toggled {
    border-radius: 20px 20px 0px 0px;
}

.select-dropdown--fit {
    width: 100%;
}

.select-dropdown--push {
    .select-dropdown__select {
        border-bottom: 1px solid #C6C7D1;
    }

    .select-dropdown__content {
        position: unset;
        border: none;
    }
}

.select-dropdown__outer {
    position: relative;

    display: flex;
    flex-direction: column;

    gap: 20px;
}

.select-dropdown-outer__label {
    font-size: 14px;
    color: #354859;
}

.select-dropdown-outer__error {
    font-size: 14px;
    color: $color-pink;
}


.select-dropdown__text {
    height: 100%;

    box-sizing: border-box;

    font-size: 16px;

    color: #354859;

    max-width: calc(100% - 57px);
    padding: 0px 16px;

    display: flex;
    align-items: center;

    @include text-shorten;
}

.select-dropdown__icon {
    height: 100%;
    padding: 0px 8px;

    border-left: 1px solid #C6C7D1;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    cursor: pointer;
}

.select-dropdown__content {
    position: absolute;
    z-index: 1;

    background-color: white;
    border: 1px solid #C6C7D1;
    border-radius: 0px 0px 20px 20px;

    left: 0px;

    width: inherit;
}

.select-dropdown__select {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
}

.select-dropdown__select--toggled {
    border-bottom: 1px solid #C6C7D1;
}

.select-dropdown__arrow {
    transition: transform .3s linear;
}

.select-dropdown__arrow--toggled {
    transform: rotateX(180deg);
}

.select-dropdown__item {
    font-size: 16px;
    text-transform: capitalize;

    padding: 14px 24px;

    border-bottom: 1px solid #C6C7D1;

    display: flex;
    align-items: center;

    color: #354859;

    text-transform: capitalize;

    cursor: pointer;

    transition: 0.3s;

    img {
        margin-right: 10px;
    }

    &:last-child {
        border-bottom: none;
        border-radius: 0px 0px 20px 20px;
    }

    &:hover {
        background-color: #dedede;
    }

    &--selected {
        background-color: #FCF7FF;
    }
}