.MuiInputLabel-root[data-shrink=true] {
    display: none !important;
}

fieldset {
    display: none;
}

.input-date__outer {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.MuiInputBase-Input, .MuiInputBase-input, .MuiFormLabel-root {
    font-size: 17px !important;
    color: #929BA8 !important;

    //letter-spacing: 2px !important;

    text-transform: uppercase !important;
    font-family: Inter !important;
}

.input-date__container {
    border: 1px solid #EFEDED;

    border-radius: 10px;

    background-color: white;

    font-size: 17px;
    color: #929BA8;

    letter-spacing: 5px;

    text-transform: uppercase;
    font-family: Inter;

    box-sizing: border-box;

    padding-right: 24px;

    align-items: center;
    display: flex;

    width: fit-content;

    flex-wrap: wrap;

    .input-date {
        border-radius: 0px;
        border: 0px;
        padding: 0px;

        padding-left: 24px;
    }

    img {
        margin-right: 5px;

        &:last-child {
            margin-right: 0px;
        }
    }

    @media (min-width: 650px) {
        
    }
}

.MuiInput-underline {
    &:before, &::after {
        display: none;
    }
}

.MuiInputBase-input {
    //width: 120px !important;
}

.MuiSvgIcon-root {
    path {
        fill: #C6C7D2;
    }
}

.input-date {
    border: 1px solid #EFEDED;

    border-radius: 10px;

    padding: 0px 24px; 

    height: 58px;

    background-color: transparent;

    box-sizing: border-box;

    position: relative;

    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;

    &::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: pointer;
    }
}

.input-date__label {
    font-size: 12px;
    color: #929BA8;
}