.myteam__members {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 32px;

    @media (max-width: 1900px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1575px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1240px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 736px) {
        grid-template-columns: repeat(1, 1fr);
    }
}