.customSlider {
    width: 100%;
    max-width: 600px;

    --main-color: white;
    --main-color-hover: rgb(104, 117, 217, 0.2);
    --track-color: #C1D4E8;
    --mark-color: #DBE6F2;

    height: 20px;

    display: flex;
    align-items: end;

    margin-top: 60px;
    margin-bottom: 30px;
}

.customSlider-track {
    height: 20px;
    /* default color of your track */
    background: var(--track-color);

    border-radius: 10px;
}

.customSlider-track.customSlider-track-0 {
    /* color of the track before the thumb */
    
}

.customSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    top: -11.25px;
    background: var(--main-color);
    /* shape of the thumb: circle */
    width: 45px;
    height: 45px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
}

.customSlider-mark {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: 32px;
    top: -60px;

    p {
        white-space: nowrap;
        color: #929BA8;
    }
}

.customSlider-mark__inner {
    cursor: pointer;

    width: 38px;
    height: 38px;
    background-color: var(--mark-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customSlider-mark-outer {

}
