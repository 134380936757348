.nav-button {
    height: 35px;
    width: 35px;

    border-radius: 50%;

    background-color: white;

    border: none;
    outline: none;
    
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    color: white;
    background-color: #929BA8;

    font-size: 12px;

    &:hover {
        background-color: #929BA8;
        color: white;

        svg path {
            stroke: white;
        }
    }

    svg {
        @include phone {
            margin-left: 9px;
        }
    }

    .nav-button__text {
        display: none;

        @include phone {
            display: block;
        }
    }

    @include phone {
        width: unset;
        border-radius: 20px;
        padding: 0px 20px;
    }

    &--mobile {
        width: 35px;
        padding: 0px;
        .nav-button__text {
            display: none;



        }
        svg {
            margin-left: 2px;
        }
        svg path {
            stroke: white;
        }
    }
}

.nav-button__off {
    background-color: white !important;
    color: #61707F !important;

    cursor: unset !important;

    svg path {
        stroke: #61707F !important;
    }
}

.nav-button__on {
    color: white;
    background-color: #929BA8;
}

.nav-button__next {
    svg {
        @include phone {
            margin-left: 9px;
        }
    }
}

.nav-button__previous {
    flex-direction: row-reverse;

    svg {
        transform: rotate(180deg);
        margin-left: 0px;

        @include phone {
            margin-right: 9px;
        }
    }
}

.nav-button__next, .nav-button__previous {
    background-color: $color-blue;
    color: white;

    svg path {
        stroke: white;
    }

    &:hover {
        background-color: darken($color-blue, 10%);
    }

    &:active {
        background-color: lighten($color-blue, 5%);
    }
}
