.pill {
    width: fit-content;

    border-radius: 26px;

    color: white;

    display: flex;
    align-items: center;

    background-color: #61707F;

    img {
        margin-left: 11px;
        cursor: pointer;
    }
}

.pills-container {
    display: flex;
    flex-wrap: wrap;

    gap: 20px;
    row-gap: 10px;
}

.pill--small {
    padding: 8.5px 20px;

    font-size: 12px;
}

.pill--big {
    padding: 8.5px 24px;

    font-size: 16px;

    background-color: #354859;
}

.pill__spans {
    display: flex;
    align-items: center;
}

.small-pill__icon {
    width: 15px;
    height: 15px;
}

.small-pill__span-item {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 2px;
    &--with-margin {
        margin-right: 15px;

    }
}

.small-pill__span-item-counter {
    position: absolute;
    background: $color-orange;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: -70%;
    left: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
}

.small-pill__inner {
    display: flex;
    justify-items: flex-start;
    align-items: center;

}

.small-pill__arrow {
    display: flex;
    align-items: center;
    margin-left: 7px;
}

