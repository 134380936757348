.checkbox__outer {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.checkbox__error {
    color: $color-red !important;
    font-size: 14px !important;
}

.checkbox__inner {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        font-size: 14px;
        color: #61707F;
    }
}

.checkbox-square {
    label {
        border-radius: 6px !important;
        margin-left: 10px;
    }
}

.checkbox {
    &--disabled {
        opacity: 0.2;
        user-select: none;

        label {
            cursor: unset !important;
        }
    }
}

.checkbox-round {
    position: relative;

    height: 28px;
    width: 28px;

    margin-right: 20px;
  }

.checkbox-round label {
    background-color: transparent;
    border: 1px solid #C6C7D1;
    border-radius: 50%;

    cursor: pointer;

    left: 0;
    position: absolute;
    top: 0;

    width: 28px;
    height: 28px;
}

.checkbox-round label:after {
    border: 0.15em solid white;
    border-top: none;
    border-right: none;
    content: "";
    height: 0.3em;
    left: 7px;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 0.8em;
    opacity: 0;
}

.checkbox-round input[type="checkbox"] {
    visibility: hidden;

    &:checked {
        background-color: red;
    }
}

.checkbox-round input[type="checkbox"]:checked + label {
    background-color: #929BA8;
    border: none;

    border: 8px solid #354859;
    box-sizing: border-box;

    &:after {
        border: none;
    }
}

.checkbox-square input[type="checkbox"]:checked + label {
    background-color: #61707F;
    border: none;

    &:after {
        border: 0.15em solid white;
        border-top: none;
        border-right: none;
    }
}

.checkbox-round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

