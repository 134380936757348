.side-popup-head__breadcrumb {
    display: flex;

    gap: 10px;

    font-size: 20px;

    span {
        color: #C6C7D2;
    }

    p {
        &:last-child {
            color: $color-blue;
        }
    }


}

.grant-details-popup {
    input {
        background-color: #FFFFFF !important;
    }
}
