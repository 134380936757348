#contact-messages {
    height: 100%;
}

#contact-conversations {
    height: 100%;

    .account {
        height: 100%;

        display: flex;
        justify-content: center;
    }
}