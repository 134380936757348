

@mixin sidebar-image-icon {
    img {
        width: 30px;
        height: 30px;

        margin-right: 12px;

        //filter: invert(94%) sepia(6%) saturate(301%) hue-rotate(166deg) brightness(91%) contrast(98%);
    }
}

.sidebar {
    border-right: 1px solid $border-color;

    display: block;

    background-color: #ffffff;
    position: absolute;

    transform: translateX(-100%);

    width: 100%;
    height: 100%;

    padding: 20px 0px;

    z-index: 9;

    @include phone {
        width: 183px;
        min-width: 183px;
    }

    @include desktop {
        width: 183px !important;
        min-width: 183px;

        position: fixed;
        transform: translateX(0%) !important;

        height: 100%;
    }

    

    overflow-x: hidden;

    &.open {
        .sidebar__inner {
            margin-left: -100%;
        }
    }

}

.sidebar__col {
    width: 100%;
}


.sidebar__inner {
    width: 200%;
    position: relative;
    margin-left: 0%;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
}

.sidebar__links {
    height: 100%;
    width: 200%;


    display: flex;
    flex-direction: column;

    padding-left: 28px;
    padding-right: 17px;

    overflow: auto;

    a {
        text-decoration: none;
        color: #000000;
    }
}


.sidebar__notifications {
    display: flex;
    flex-direction: row;
}

.sidebar-notification {
    width: 50%;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 17px;
    }

    span {
        width: 30px;
        height: 30px;

        background-color: #1577EA;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        color: white;
    }

    &--toggled {
        background-color: #EFF2F4;
        border-radius: 0px 0px 0px 20px;
    }
}

