.pagination {
    width: 100%;
    @include flex-row-space-between;
    align-items: center;
    max-width: 800px;
    margin: auto;
    margin-top: 50px;


    button {
        width: 120px;
        height: 30px;

        font-size: 12px;

        display: block;
    }

    .Dropdown-root {
        width: 181px;

        .Dropdown-control {
            height: 56px;
            background-color: white;
        }

        .Dropdown-menu {
            background-color: white;
            border-radius: 20px;
        }
    }
}
