.companies__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 32px;

    position: relative;

    @media (max-width: 1900px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1575px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1240px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 736px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .no-data-error {
        width: 100%;
        position: absolute;

        top: 100px;

        @include tablet {
            
        }
    }
}

.companies-settings__section {
    padding-bottom: 40px;
    margin-top: 40px;
    border-bottom: 1px solid #D2DBE2;

    &:first-child {
        margin-top: 0px;
    }

    &:last-child {
        border-bottom: 0px;
    }
}

.companies-settings__section-name {
    margin-bottom: 20px;
}

.companies-settings {

    width: 100%;
    max-width: 1700px;
    margin: auto;
    margin-top: 40px !important;
}

.companies-settings__profile {
    margin-top: 25px;
}

.companies-settings__row {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;

    .input-outer {
        width: 100%;

        margin-right: 26px;

        margin-bottom: 24px;

        input {
            background-color: #F5F8FA;
        }

        &:last-child {
            margin-right: 0px;
        }

        @include tablet {
            margin-bottom: 0px;
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }

    @include tablet {
        flex-direction: row;
    }
}

