.search-outer {
    width: 100%;

    position: absolute;
    display: none;

    z-index: 10;
    padding: 18px;

    top: 61px;

    border-bottom: 1px solid $border-color;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px;
    border-radius: 0px 0px 20px 20px;

    background-color: white;

    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    input {
        margin: 0px !important;
        max-width: none !important;
    }
}