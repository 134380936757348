.website__topbar {
    button {
        margin-top: 20px;
    }
    
    @include desktop {

    }
}

.content-search {}

.content-search__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include desktop-extra-large {
        flex-direction: row;
    }
}

.content-box {
    box-sizing: border-box;
    padding: 18px;
    background-color: #F5F8FA;
    border-radius: $border-radius;

    height: 100%;
}

.content-searcher__search {
    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: 1px solid #D2DBE2;
}

.content-searcher__results {
    font-size: 14px;
    color: #00C8C3;
}

.content-search__button {
    width: 30px;
    height: 30px;

    border-radius: 50%;

    background-color: #EFF2F4;

    cursor: pointer;

    @include flex-center;
}

.content-box__title {
    font-size: 14px;
}

.content-searcher {
    width: 100%;

    @include desktop-extra-large {
        width: 55.8%;
    }
}

.content-filter {
    width: 100%;
    margin-top: 20px;

    @include desktop-extra-large {
        margin-top: 0px;
        width: 43.5%;
    }
}

.content-searcher__bottom {
    @include flex-row-space-between;

    align-items: center;

    margin-top: 20px;

    button {
        width: 120px;
        height: 30px;

        font-size: 12px;

        display: block;
    }
    
    .Dropdown-root {
        width: 181px;
        
        .Dropdown-control {
            height: 56px;
            background-color: white;
        }

        .Dropdown-menu {
            background-color: white;
            border-radius: 20px;
        }
    }
}

.results-outer {
    margin-top: 20px;
    max-height: 526px;

    padding-top: 3px;
    padding-bottom: 5px;

    overflow-y: auto;
}

.result-item {
    width: calc(100% - 6px);
    height: 50px;

    border-radius: $border-radius;
    background-color: white;

    margin-bottom: 18px;
    margin-left: 3px;
    margin-right: 3px;

    justify-content: space-between;

    @include flex-row-centered;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;

        button {
            background-color: #00C8C3;
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.result-item__item {
    @include flex-row-centered;

    padding: 0px 15px;

    font-size: 12px;

    height: 100%;

    p {
        color: #1577EA;

        width: 100%;

        @include text-shorten;
    }

    &:first-child {
        
    }

    &:nth-child(2) {
        border-left: 1px solid #D2DBE2;
        border-right: 1px solid #D2DBE2;

        width: 120px;
        max-width: 120px;
    }

    a {
        height: 30px;
        font-size: 12px;
    }
}

.result-item__left {
    @include flex-row-centered;

    padding: 10px;
    box-sizing: border-box
}

.result-item__right {
    @include flex-row-centered;

    height: 100%;
}

.result-item__ball {
    width: 25px;
    height: 25px;

    border-radius: $border-radius;
    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
}

.result-item__title {
    margin-left: 10px;
    font-size: 14px;
}

.content-filter__section {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D2DBE2;

    &:last-child {
        border-bottom: 0px;
        padding-bottom: 0px;
    }
}

.content-filter__title {
    font-size: 20px;
    font-weight: 300;
}

.distributions-outer {
    display: grid;
    grid-gap: 20px 30px;
    grid-template-columns: repeat(1, 1fr);

    margin-top: 20px;

    button {
        font-size: 14px;
        width: unset;
        color: black;
        min-width: 0;

        @include text-shorten;
    }

    @include desktop-large {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop-extra-large {
        grid-template-columns: repeat(3, 1fr);
    }
}

.content-types {
    display: grid;
    grid-gap: 20px 30px;
    grid-template-columns: repeat(1, 1fr);

    margin-top: 20px;

    @include desktop-large {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop-extra-large {
        grid-template-columns: repeat(3, 1fr);
    }
}

.content-type {
    padding: 0px 20px;
    box-sizing: border-box;

    font-size: 14px;

    height: 40px;
    border-radius: $border-radius;
    background-color: #EFF2F4;

    align-items: center;

    min-width: 0;

    @include flex-row-space-between;

    p {
        @include text-shorten;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
    }
}

.content-type--selected {
    background-color: $button-confirm;
    color: white;
}