
.table__outer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.table__error {
    font-size: 14px;
    color: $color-red;
}

table {
    width: 100%;
    border-collapse: collapse;

    table-layout: fixed;
    word-wrap: break-word;

    textarea {
        resize: none;
    }
}

th {
    background-color: #FCF7FF;
}

.table__outer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.table__error {
    font-size: 14px;
    color: $color-red;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 20px;

    font-size: 14px;
    font-weight: 400;

    color: #61707F;
}

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
  }
  
  table tr {
    border: 1px solid #ddd;
  }
  
  table th {
    font-size: .85em;
    letter-spacing: .1em;
  }
  
  @media screen and (max-width: 800px) {
      table {
          border: none;
      }

        table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        }
        
        table tr {
            display: block;
            margin-bottom: .625em;
        }
        
        table td {
            display: block;
            font-size: .8em;
            //text-align: right;

            display: flex;
            flex-direction: column;

            padding: 0px;

            border: none;
            border-bottom: 1px solid #dddddd;

            div {
                font-family: Inter;
                width: 100%;
                border: none;
                outline: none;

                padding: 20px;
                box-sizing: border-box;
            }

            &:last-child {
                border-bottom: none;
            }
        }
        
        table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
            content: attr(data-label);
            float: left;

            font-size: 14px;
            font-weight: 400;

            padding: 20px;
            box-sizing: border-box;

            background-color: #FCF7FF;
        }
  }