@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap');

// Utils

@import './sass-utils/variables';
@import './sass-utils/mixins';
@import './sass-utils/animations';
@import './sass-utils/utils';

// Layouts

@import './base-layout.scss';
@import './containers/index';

// Components

@import './components/tasks';
@import './components/video-container';

@import './components/e-learning/index';
@import './components/document-upload';

@import './components/divider';
@import './components/state';
@import './components/error-container';
@import './components/inputs/inputs';
@import './components/label-container';
@import './components/side-popup-progress';
@import './components/input-currency';
@import './components/application-decision';
@import './components/chatbox';
@import './components/table';
@import './components/question-type/question-type';
@import './components/dropdown';
@import './components/grant-details';
@import './components/textarea';
@import './components/grants-card';
@import './components/sponsors';
@import './components/nav-button';
@import './components/custom-dropdown';
@import './components/pills';
@import './components/topbar';
@import './components/sidebar';
@import './components/notification-item';
@import './components/search-bar';
@import './components/user-dropdown';
@import './components/search-outer';
@import './components/input-outer';
@import './components/button';
@import './components/checkmark';
@import './components/forgot-password';
@import './components/editor';
@import './components/editor-image';
@import './components/popup';
@import './components/new-version';
@import './components/popup';
@import './components/team-member';
@import './components/team-members';
@import './components/side-popup';
@import './components/modify-member';
@import './components/new-user';
@import './components/team-members-error';
@import './components/notifications-popup';
@import './components/messages-popup';
@import './components/alert';
@import './components/free-content-buttons';
@import './components/results-error';
@import './components/search-box';
@import './components/company-item';
@import './components/images-browse';
@import './components/tab-bar';
@import './components/no-data-error';
@import './components/custom-switch';
@import "./components/editor-preview";
@import "./components/pagination";
@import "./components/blue-notification-button";
@import "./components/list-card";
@import "./components/image-button";
@import "./components/activity-list";
@import "./components/video-full-width";
@import "./components/editor-gallery";
@import "./components/sidebar-menu";
@import "./components/content-container";
@import "./components/results-list/results-list-item";
@import "./components/results-list/results-list";
@import './components/checkbox';
@import './components/file-uploader';
@import './components/eligibility-question';
@import './components/input-date';
@import './components/application-activity';
@import './components/sticky-container';
@import './components/label-container';
@import './components/inputs/inputs';
@import './components/grant-question-block';
@import './components/appointment-cal';

// Containers
@import "./containers/layout";
@import './containers/index';

// Views

@import './views/e-learning/e-learning-page.scss';

@import './views/grant-application-view.scss';
@import './views/grant-editor.scss';
@import "./views/grants-preview.scss";
@import './views/grant-preview-status-bar.scss';
@import './views/grants.scss';
@import './views/login.scss';
@import './views/register.scss';
@import './views/reset-password.scss';
@import './views/content-editor.scss';
@import './views/content-search.scss';
@import './views/account.scss';
@import './views/myteam.scss';
@import './views/content-distributions.scss';
@import './views/content-distribution-settings.scss';
@import './views/error.scss';
@import './views/companies.scss';
@import './views/search-results.scss';
@import "./views/dashboard.scss";
@import './views/request-reset.scss';
@import './views/contact-messages';
