.myteam {
    .website__topbar {
        flex-direction: column;
        align-items: flex-start;

        .website-topbar__filter {
            font-size: 10px;
            margin: 20px 0px;
        }

        button {
            margin-top: 20px;
        }

        @include desktop {
            flex-direction: row;
            align-items: center;

            .website-topbar__filter {
                font-size: 16px;
                margin: 0px;
            }

            button {
                margin-top: 0px;
            }
        }   
    }
}

.website-topbar__settings {
    margin-top: 20px;

    @include desktop {
        margin-top: 0px;
    }
}