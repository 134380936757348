#forgot-password {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    .popup__buttons {
        button {
            
        }
    }
}
