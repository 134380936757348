.input-outer {
    display: flex;
    flex-direction: column;

    label {
        font-size: 16px;
        color: #929BA8;
        margin-bottom: 10px;
    }

    input, textarea {
        height: 60px;

        border: 1px solid #D2DBE2;
        outline: none;

        border-radius: 20px;

        font-size: 16px;
        background-color: #F5F8FA;
        box-sizing: border-box;

        padding: 0px 17px;

        &:read-only {
          background-color: #FFFFFF !important;
            border: none;
            padding-left: 0px;
        }
    }

    textarea {
        resize: none;
        min-height: 200px;
        padding: 20px;
    }

    .Dropdown-root {
        width: 100%;

        .ucfirst, .Dropdown-placeholder {
            text-transform: capitalize;
        }
    }

        //textarea {
    //    width: 100%;
    //    height: fit-content;
    //
    //    font-family: Arial;
    //
    //    border: 1px solid #D2DBE2;
    //    outline: none;
    //
    //    border-radius: 3px;
    //
    //    font-size: 16px;
    //
    //    padding: 17px ;
    //    box-sizing: border-box;
    //}

    &--full {
        width: 100%;
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.input-outer__select {
    display: flex;
    flex-direction: row;

    input {
        width: 70%;
        border-radius: 20px 0px 0px 20px;
    }

    button {
        width: 30%;
        height: auto;

        outline: none;
        border: none;

        color: white;

        background-color: $color-blue;
        border-radius: 0px 20px 20px 0px;

        padding: 0px 10px;
        &:hover  {
            cursor: pointer;
            background-color: #000000;
        }
    }


}

//.input-outer__error {
//    border: 1px solid $color-pink !important;
//}

.input-outer__small {
    input {
        height: 30px;
    }
}

.input-outer__row {
    @include flex-row-centered;
}

.input-outer__errors {
    margin-top: 15px;

    p {
        color: $color-pink;
        font-size: 12px;
        margin-bottom: 0px;
    }
}
