.search-bar {
    width: calc(100% - 147px);
    height: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    outline: none;
    padding: 0px 10px;
    border: none;
    font-size: 12px;
    background-color: transparent;
}

.search-bar--white {
    background-color: white !important;
}

.search-bar--toggled {
    border-radius: $border-radius $border-radius 0px 0px;
}

.search-bar__outer {
    max-width: 569px;
    width: 100%;

    display: flex;
    flex-direction: column;
    position: relative;
}

.search-bar__icon {
    width: 24px;
    height: 24px;

    margin-left: 10px;
}

.search-bar__inner {
    width: 100%;
    height: 35px;

    border-radius: 20px;
    outline: none;
    border: 1px solid #BEC0CB;
    background-color: #F5F8FA;
    display: flex;
    align-items: center;


    button {
        width: 112.59px;
        height: 25px;

        border-radius: 20px;

        outline: none;
        border: none;

        background: #1577EA;
        color: white;

        margin-right: 10px;
        
        font-size: 12px;

        padding: 0px 20px;
        box-sizing: border-box;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.search-bar__results {
    width: 100%;
    top: 30px;
    margin: 0px 20px;

    position: absolute;
    box-sizing: border-box;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #EFF2F4;

    box-shadow: $box-shadow;

    border-radius: $border-radius;
}

.search-bar__results-head {
    box-sizing: border-box;
    padding: 16px;

    color: #707070;
    font-size: 12px;

    background-color: #EFF2F4;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.search-bar__results-bottom {
    display: flex;
    align-items: center;
    justify-content: center;

    border-top: 1px solid $border-color;

    box-sizing: border-box;
    padding: 20px 0px;

    font-size: 12px;
    color: $color-blue;

    cursor: pointer;
}

.results-head__refine {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    font-size: 12px;
    color: $color-blue;

    cursor: pointer;

    img {
        margin-left: 20px;
        filter: invert(29%) sepia(62%) saturate(3827%) hue-rotate(203deg) brightness(102%) contrast(84%);
    }
}

.search-bar__results-body {
    max-height: 180px;

    box-sizing: border-box;
    padding: 9px;

    overflow: auto;
    overflow-y: none;
}

.search-result {
    padding: 5px 10px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: $border-radius;

    margin-bottom: 7px;

    p, b {
        font-size: 14px;
    }

    b {
        font-weight: 500;
    }

    &:hover {
        background-color: $color-blue;
        cursor: pointer;

        p {
            color: white;
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }
}