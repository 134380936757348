@keyframes slide-in {
    100% { 
        transform: translateX(0%); 
    }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@keyframes slide-in-right {
    100% { 
        transform: translateX(calc(100% - 546px)); 
    }
}

@keyframes slide-in-right-tablet {
    100% { 
        transform: translateX(0%); 
    }
}

@keyframes slide-out-right {
    0% { transform: translateX(calc(100% - 546px)); }
    100% { transform: translateX(100%); }
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

.slide-in-right {
    animation: slide-in-right 0.5s forwards;
    -webkit-animation: slide-in-right 0.5s forwards;
}

.slide-in-right-tablet {
    animation: slide-in-right-tablet 0.5s forwards;
    -webkit-animation: slide-in-right-tablet 0.5s forwards;
}

.slide-out-right {
    animation: slide-out-right 0.5s forwards;
    -webkit-animation: slide-out-right 0.5s forwards;
}
