.team-member {
    min-width: 100%;

    padding: 37px 28px;
    box-sizing: border-box;

    background-color: $item-background;

    border-radius: 20px;

    button {
        width: 100%;
    }

    @include desktop {
        min-width: 300px;
    }
}

.team-member__header {
    @include flex-row-centered;

    img {
        width: 90px;
        height: 90px;
        border-radius: 50%;

        margin-right: 32px;
    }
}

.team-member__buttons {
    margin-top: 14px;

    display: flex;
    flex-direction: row;

    button  {
        margin-right: 24px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.team-member__info {
    margin-top: 36px;
    margin-bottom: 33px;

    p {
        font-size: 12px;
        margin-top: 5px;
    }
}

.user-info__role {
    margin-bottom: 14px;
    color: $color-blue;
}

.user-info__jobrole {
    margin-bottom: 17px;

    font-size: 16px !important;
}
