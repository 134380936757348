.e-learning-page__dropdown {
    background-color: #F5F8FA;
    filter: unset;
    border-radius: 20px;

    .dropdown__content {
        padding: 15px 21px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .label-container {
        label {
            color: #929BA8;
        }

        p {
            color: #354859;
        }
    }
}

.e-learning-page__dropdown-module {
    background-color: white;
    border-radius: 20px;

    .dropdown__head {
        height: 55px;
        padding: 0px 21px;

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .dropdown__toggle {
        width: 35px;
        height: 35px;

        background-color: #61707F;
        border: unset;

        img {
            filter: invert(1);
            rotate: 90deg;
        }
    }
}

.e-learning-page__dropdown__head {
    border-radius: 20px;
}

.e-learning-page__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.e-learning-page__option {
    display: flex;
    align-items: center;
    gap: 10px;

    p, b {
        color: #354859;
        font-size: 14px;
    }
}

.e-learning-page__options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}