.new-version, .new-content {
    .Dropdown-root {
        width: 100%;
    
        .Dropdown-control {
            height: 60px;
            border: 1px solid #D2DBE2;
            background-color: white;

            outline: none;
            border-radius: 20px;
            font-size: 16px;
            padding: 0px 17px;
    
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
    
            .Dropdown-placeholder {
                @include text-shorten;
            }
        }
    
        .Dropdown-option {
            white-space: nowrap;
        }
    
        .Dropdown-menu {
            width: 100%;

            background-color: white;
            border-radius: 20px;

            margin-top: 0px;

            max-height: 150px;
        }
    
        .Dropdown-arrow {
            top: unset;
            right: unset;
        }
    }

    .input-outer {
        margin-bottom: 30px !important;
    }
}