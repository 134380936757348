.distributions-outer {
    display: grid;
    grid-template-columns: repeat(5, 300px);
    grid-gap: 32px;

    @media (max-width: 1900px) {
        grid-template-columns: repeat(4, 300px);
    }

    @media (max-width: 1575px) {
        grid-template-columns: repeat(3, 300px);
    }

    @media (max-width: 1240px) {
        grid-template-columns: repeat(2, 300px);
    }

    @media (max-width: 736px) {
        grid-template-columns: repeat(1, 300px);
    }
}

.distribution-item {
    max-width: 300px;
    height: 370px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.distribution-item__img {
    width: 100%;
    height: 165px;

    background-color: #D2DBE2;
    border-radius: $border-radius $border-radius 0px 0px;
    background-size: cover !important;
    background-position: center center !important;
}

.distribution-item__content {
    height: calc(100% - 165px);

    box-sizing: border-box;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {

        font-size: 12px;

        &:first-child {
            color: $color-blue;
        }
    }

    p, b {
        @include text-shorten;
    }

    button {
        width: 100%;
    }
}

.distribution-item__type {
    text-transform: capitalize;
}

.distribution-item__name {
    text-transform: capitalize;

}
