.application-decision {
    h1 span {
        color: $color-blue;
    }
}

.application-decision__approve {
    .popup__buttons {
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        .button {
            width: 100%;
            margin-bottom: 0px !important;

            @media (min-width: 400px) {
                width: fit-content;
            }
        }

        @media (min-width: 400px) {
            flex-direction: row;
        }
    }
}

.application-decision-approve__content {
    padding-top: 40px;
    border-top: 1px solid #C6C7D1;
    margin-top: 30px;
}