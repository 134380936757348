.video-full-width {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
}

.video-full-width__video {

    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.video-full-width__overlay {
    position: absolute;
    background: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
