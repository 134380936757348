.eligibility-question {

}

.eligibility-question__checkboxes {
    display: flex;
    flex-direction: column;

    gap: 20px;

    padding: 21px;

    box-sizing: border-box;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.eligibility-question-checkbox {
    flex-wrap: wrap;

    .input-outer {
        flex-grow: 1;
    }

    .column__delete {
        margin-top: 0px;
    }
}

.eligibility-question__buttons {
    display: flex;
    flex-wrap: wrap;

    gap: 20px;

    button {
        flex-grow: 1;
    }
}

.eligibility-question__documents {
    display: flex;
    flex-direction: row;

    gap: 20px;

    flex-wrap: wrap;
}

.eligibility-question-file {
    width: 148px;
}

.eligibility-question-file__icon {
    height: 148px;

    border-radius: 10px;

    border: 1px solid #C6C7D1;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 36px;
        height: 36px;
    }
}

.eligibility-question-file__text {
    display: flex;
    flex-direction: column;

    align-items: center;

    margin-top: 10px;
    gap: 13px;

    p {
        font-size: 12px;
        color: #707070;
    }
}

.eligibility-question-file__delete {
    color: $color-red !important;
    cursor: pointer;
}