.image-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: $theme-grey;
    padding: 12px 30px 12px 30px;
    border-radius: 20px;

    img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
    }

    transition: 0.4s;
    &:hover {
        cursor: pointer;
        background: $color-medium-grey;
    }

}
