.search-result__outer {
    .website__topbar {
        .Dropdown-root {
            width: 215px !important;
        }
    }
}

.search-results {
    .result-item__right {
        .result-item__item {
            border: none;

            &:first-child {
                border-left: 1px solid #D2DBE2;
                border-right: 1px solid #D2DBE2;
            }

            &:last-child {
                width: unset;
            }

            button {
                width: 111px;
                justify-content: center;
            }
        }
    }
}

.search-results__outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    margin-top: 35px;
}

.search-results__results-text {
    font-size: 21px;
    color: #BABABA;
}

.search-results__preview {
    width: 67%;

    max-height: 673px;
    height: 100%;
}

.search-results__bottom {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 50px;

    button {
        height: 30px;
    }
}

.search-results__filter {
    width: 337px;
    height: 456px;

    max-height: 456px;

    border-radius: $border-radius;

    background-color: #F5F8FA;

    box-sizing: border-box;
    padding: 20px;
}

.search-filter__title {
    font-size: 14px;
}

.search-filter__subtitle {
    font-size: 20px;
    margin-top: 23.5px;
}

.search-filter__filters {
    display: flex;
    flex-direction: column;

    margin-top: 20px;

    overflow: auto;
    overflow-y: auto;
}

.search-filter {
    height: 40px;

    padding: 0px 20px;

    border-radius: $border-radius;

    align-items: center;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: #00C8C3;

    margin-bottom: 20px;

    font-size: 14px;

    span {
        color: white;
        font-size: 14px;
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.result-item {
    background-color: #EFF2F4;
}

.result-item__left {
    
}