.label-container {
    display: flex;
    flex-direction: column;

    gap: 20px;

    label {
        font-size: 12px;
        margin: 0px;
    }
}