.register {
    width: 100%;

    background-image: url($register-image);
    background-size: cover;

    .website-row {
        width: 100%;

        margin-bottom: 33px;

        &:last-child {
            margin-bottom: 0px;
        }

        .input-outer {
            margin-bottom: 33px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    @include desktop {
    
        .website-row {
            width: 100%;
    
            display: flex;
            flex-direction: row;
    
            margin-bottom: 20px;
    
            &:last-child {
                margin-bottom: 0px;
            }
    
            .input-outer {
                width: 100%;
    
                margin-right: 10px;
                margin-bottom: 0px;
    
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}

.register-desktop {
    width: 100%;

    margin-bottom: 20px;

    display: none;

    flex-direction: column;
    align-items: center;

    .bottom {
        flex-direction: column;
        justify-content: unset;

        margin-top: 22px;

        button {
            margin-top: 20px;
        }
    }

    .checkmark-box {
        align-items: unset;

        .round {
            margin-top: -7px;
        }
    }

    .website-row {
        p {
            padding-right: 20px;
        }
    }

    @include desktop-extra-large {
        .bottom {
            flex-direction: row;
            justify-content: space-between;

            .checkmark-box {
                max-width: 200px;
            }

            button {
                margin-top: 00px;
            }
        }
    }

    @include desktop {
        display: flex;
    }
}

.register-mobile {
    width: 100%;

    display: block;

    .checkmark-box {
        max-width: 100%;
    }

    .bottom {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .website-row__item {
            width: 100%;

            button {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    @include desktop {
        display: none;
    }
}

.unset {
    align-items: unset !important;
}

.register__inner {
    max-width: 100%;
    padding: 50px 36px;
    margin-bottom: 80px;

    justify-content: unset;

    margin: auto;

    background-color: #ffffff;

    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    padding: 50px;

    h1, h2 {
        font-weight: 400;
    }

    h1 {
        font-size: 38px;
        margin-bottom: 50px;

        @include desktop {
            text-align: center;
            font-size: 60px;
            margin-bottom: 91px;
        }
    }

    h2 {
        font-size: 30px;
        margin-bottom: 53px;

        @include tablet {
            text-align: center;
        }   
    }

    @include desktop {
        width: 100%;
        max-width: 1550px;
        min-height: 100vh;
        
        padding: 0px 201px;

        justify-content: center;
    }
}

.link {
    color: #D2DBE2;
}

