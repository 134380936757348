.grant-application-view__information {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    gap: 31px 100px;

    width: 100%;

    @media (min-width: 1420px) {
        width: 60%;
    }
}

.grant-application-view__top {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;

    margin-bottom: 28px;

    gap: 50px;

    @media (min-width: 1420px) {
        flex-direction: row;
    }
}

.grant-application-view__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 20px;

    @media (min-width: 1420px) {
        align-items: flex-end;
    }

    .utils__row {
        justify-content: flex-start;
        flex-wrap: wrap;

        width: 100%;

        .button {
            width: 100%;

            @media (min-width: 470px) {
                width: unset;
            }
        }

        .select-dropdown__outer {
            width: 100% !important ;

            @media (min-width: 470px) {
                width: unset !important;
            }
        }

        .select-dropdown {
            width: 100%;

            &__content {
                width: 100%;
            }

            @media (min-width: 470px) {
                width: 267px;

                &__content {
                    width: 267px;
                }
            }
        }

        @media (min-width: 470px) {
            width: unset;
        }

        @media (min-width: 1420px) {
            justify-content: flex-end;
            flex-wrap: unset;
        }
    }

    .input__row {
        p {
            font-size: 12px;
            color: #929BA8;

            max-width: 60px;

            text-align: center;
        }

        .input-outer {
            width: 150px;

            input {
                background-color: transparent;
            }
        }
    }
}

.application-view__section {
    margin-top: 29px;

    display: flex;
    flex-direction: column;

    gap: 40px;
}

.information-section {
    display: flex;
    flex-direction: column;

    gap: 14px;

    flex: 1 1 150px;

    p {
        line-break: anywhere;
    }

    @media (min-width: 900px) {

    }
}

.information-section__title {
    font-size: 12px;
    color: #929BA8;
}

.application-view-segment {
    display: flex;
    flex-direction: column;
    flex: 1;

    gap: 24px;

    .span {
        font-size: 10px;
        padding: 5px 10px;
        background-color: #929BA8;
        color: #dce5f3;
        border-radius: 20px;
        width: fit-content;
    }

    .input-outer input, .text-area textarea {
        background-color: transparent;
    }

    .button {


        &:last-child {
            margin-left: 0px;
            max-width: unset;
            min-width: unset;

            @media (min-width: 1300px) {
                margin-left: 50px;
            }
        }
    }
}

.application-view-segment__head {
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
        margin-top: -5px;
    }
}

.application-view-segment__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (min-width: 1300px) {
        .button {
            max-width: 130px !important;
            min-width: 130px !important;
        }
    }
}

.application-view-segment__item-meta {
    width: 100%;
    text-align: right;
    padding-top: 10px;
    font-style: italic;
    color: $color-grey;
    font-size: 12px;
}

.application-view-segment__title {
    color: #929BA8;
    word-break: break-word;
}

.application-view-segment__description {
    color: #61707F;
    font-size: 14px;

    ul {
        padding-left: 15px;
    }
}

.application-view__group {
    padding: 20px;
    box-sizing: border-box;

    border-radius: 20px;

    background-color: #F9F9F9;

    .application-view-segment__title {
        color: #61707F;
    }
}

.application-view__spacing {
    padding-bottom: 25px;

    border-bottom: 1px solid #F2F2F2;
}

.application-view-group__outer {
    padding-bottom: 25px;
    margin-bottom: 25px;

    border-bottom: 1px solid #F2F2F2;

    display: flex;
    flex-direction: column;

    gap: 30px;
}

.application-view__input-grid {
    display: flex;
    flex-wrap: wrap;

    gap: 20px 35px;

    .input-outer {
        flex: 1 1 468px;
    }
}

.application-view__group .application-view-segment {
    &:last-child {
        margin-right: 0px !important;
    }
}


.application-view-question__row, .application-view__group {
    display: flex;
    justify-content: space-between;

    gap: 50px;

    .application-view-segment {
        &:first-child {
            display: flex !important;
            flex-direction: column !important;
            margin-right: unset !important;
            align-items: unset !important;
        }

        &:last-child {
            margin-right: 20px;
            flex: unset;
            flex-direction: row;
            align-items: stretch;
        }
    }
}

.comment-lock {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border: 1px solid #BEC0CB;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    cursor: pointer;

    &--toggled {
        background-color: $color-blue;
        border: none;
    }

    &--busy {
        cursor: initial;
    }
}

.comment-lock__busy-icon {

}

.application-status {
    width: 100%;

    background-color: red;

    border-radius: 0px 0px 20px 20px;

    display: flex;
    flex-direction: column;

    animation-duration: 0.4s;

    @media (min-width: 700px) {
        flex-direction: row;
    }
}

.application-status__head {
    padding: 20px;

    font-size: 20px;
    color: #FFFFFF;

    border-right: 1px solid #FFFFFF;

    text-transform: capitalize;
    text-align: center;

    box-sizing: border-box;

    @include flex-center;

    @include tablet {
        padding: 30px 50px 10px 50px;
    }

    @media (min-width: 700px) {
        padding: 0px 56px;
    }
}

.application-status__inner {
    width: 100%;

    position: relative;

    padding: 10px 50px 30px 50px;

    font-size: 14px;

    display: none;
    flex-direction: column;

    box-sizing: border-box;

    justify-content: center;
    align-items: center;

    p {
        color: white;

        white-space: break-spaces;
        text-align: center;
    }

    span {
        color: #FFFFFF;
        font-weight: 600;
    }

    @media (min-width: 700px) {
        padding: 32px 50px;
        display: flex;
    }

    @include desktop {
        white-space: nowrap;
    }
}

.application-status__outer {
    padding-bottom: 25px;
    margin-bottom: 25px;

    border-bottom: 1px solid #F2F2F2;

    &--accepted {
        .application-status-bar {
            gap: 50px;

            .utils__row {
                flex-wrap: wrap;

                &:first-child {
                    gap: 50px;

                    .information-section {
                        flex: unset;

                        &:last-child {
                            gap: 30px;
                        }
                    }
                }
            }
        }
    }

    &--rejected {
        .application-status-bar {
            gap: 20px;

            .utils__row {
                &:first-child {
                    flex: 1 1;
                }
            }
        }
    }


}

.additional-scores__outer {
    display: flex;
    flex-direction: column;

    gap: 50px;
}

.additional-scores__item {
    display: flex;
}

.additional-scores-item__row {
    flex: 50%;
}

.additional-scores-item__text {
    display: flex;
    flex-direction: column;

    gap: 14px;

    p {
        &:first-child {
            color: $color-blue;
        }

        &:last-child {
            color: #929BA8;
        }
    }
}

.react-select__control {
    border-radius: 20px !important;
    min-height: 58px !important;
    border: 1px solid #C6C7D1 !important;
    box-shadow: none !important;
    transition: unset !important;

    svg {
        transition: transform 0.3s linear;
    }

    &--menu-is-open {
        border-radius: 20px 20px 0px 0px !important;

        svg {
            transform: rotateX(180deg) !important;
        }
    }
}

.application-status--accepted {
    background-color: #00C8C3;
}

.application-status--rejected {
    background-color: #EA1557;
}

.application-status--warning {
    background-color: $color-orange;
}

.application-status--default {
    background-color: $color-grey;
}

.application-status-bar {
    margin-top: 20px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 20px 0px;

    .text-area {
        max-width: 800px;

        textarea {
            border-radius: 2px;
            height: 133px;
            background-color: transparent;
        }
    }

    .utils__row {
        align-items: unset;
    }
}

.information-section {
    display: flex;
    flex-direction: column;

    gap: 14px;

    flex: 1 1 150px;
}

.information-section__title {
    font-size: 12px;
    color: #929BA8;
    white-space: nowrap;
}


.react-select__value-container {
    padding: 2px 16px !important;
}

.react-select__indicator-separator {
    margin-top: unset !important;
    margin-bottom: unset !important;
}

.react-select__indicator {
    padding: 18px !important;
}

.react-select__input-container, .react-select__placeholder {
    color: #354859 !important;
    font-size: 16px !important;
    font-weight: 300 !important;
}

.react-select__option {
    padding: 14px 24px !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    color: #354859 !important;

    border-bottom: 1px solid #C6C7D1 !important;

    &--is-selected {
        background-color: #FCF7FF !important;
        color: black !important;
    }

    &--is-focused {
        background-color: unset !important;
    }

    &:hover {
        background-color: #dedede !important;
    }

    &:last-child {
        border-radius: 0px 0px 20px 20px !important;
        border-bottom: unset !important;
    }
}

.react-select__menu-notice {
    padding: 15px 8px !important;
}

.react-select__menu {
    border-radius: 0px 0px 20px 20px !important;
    margin-top: 0px !important;
    box-shadow: none !important;
    border: 1px solid #C6C7D1 !important;
    border-top: unset !important;
}


.react-select__menu-list {
    padding: 0px !important;
}

.assessors__outer {
    display: flex;
    flex-direction: column;

    gap: 40px;

    .react-select-container {
        width: 50%;
    }
}

.assessors-item {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.assessors-item__name {
    width: 50%;
}


.override-scoring__outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-bottom: 35px;
    border-bottom: 1px solid #D2DBE2;

    img {
        width: 50px;
        height: 50px;
    }

    h4 {
        color: #354859;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    p {
        font-size: 12px;
        color: #929BA8;
        text-align: center;
    }
}

.document-file {
    width: 148px;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;
}

.document-file__view-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: $color-orange;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.2s;
}

.document-file__icon {
    width: 148px;
    height: 148px;
    position: relative;
    border-radius: 15px;

    background-color: transparent;

    border: 1px solid #C6C7D1;

    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 36px;
        height: 36px;
    }

    &:hover {
        cursor: pointer;
        border: none;

        .document-file__view-overlay {
            opacity: 1;
        }
    }

}

.document-file__name {
    font-size: 12px;
    color: #707070;
}
