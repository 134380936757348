.content-editor {
    .website__topbar {
        flex-direction: column;
        align-items: start;

        h1 {
            max-width: 400px;
            white-space: nowrap;

            @include text-shorten;
        }
        button {
            width: unset;
        }

        .website-row {
            margin-top: 20px;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;

            width: 100%;

            .Dropdown-root {
                width: 100%;

                @media (min-width: 780px) {
                    width: 372.09px;
                }
            }

            button {
                margin-top: 20px;
                width: 100%;

                @media (min-width: 780px) {
                    width: unset;
                    margin-top: 0px;
                }
            }

            @media (min-width: 780px) {
                flex-direction: row;
                align-items: center;
                width: unset;
            }

            @media (min-width: 1400px) {
                margin-top: 0px;
            }
        }

        @media (min-width: 1400px) {
            flex-direction: row;
        }
    }
}

.slug__content-type {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: 15px;

    p {
        white-space: nowrap;
        margin-right: 15px;
    }

    strong {
        font-weight: 400;
        color: $color-green;
    }

    .custom-switch {
        display: flex;
    }
}

.details-item {
    @include flex-row-space-between;
}


.content-editor__lock-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top:0;
    z-index: 10;
    border-radius: $border-radius;
    background: lighten(#000000, 90%);
    opacity: 0.8;
}
.details-item__bottom {
    display: flex;
    align-items: end;

    button {
        width: fit-content;
        margin-left: 20px;
    }
}

.details-version {
    @include flex-row-centered;
}

.content-editor__header {
    display: flex;
    flex-direction: column;
}

.content-editor__top {
    @include flex-row-space-between;
}

.content-editor__container {
    display: flex;
    flex-direction: row;
        position: relative;
    border-radius: $border-radius;
    background-color: #F5F8FA;

    padding: 23.5px 28px;
    box-sizing: border-box;

    margin-bottom: 30.5px;

    &:last-child {
        margin-bottom: 30.5px;
    }
}



.content-editor__inner {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: 1px solid $border-color;

    @media (min-width: 1580px) {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

.content-editor__items {
    display: grid;
    grid-gap: 0px 20px;

    @media (min-width: 780px) {
        grid-template-rows: repeat(3, 1fr);
    }
}

.content-editor__content {
    display: flex;
    flex-direction: column;
}

.content-editor__item-divider {
    min-width: 526px;

    border-left: 1px solid $border-color;

    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 0px;
        font-size: 14px;

        white-space: nowrap;
    }

    input {
        width: 100%;
        height: 56px;

        margin-left: 49px;
    }

    .input-outer {
        justify-content: space-between;

        margin-top: 17px;
        margin-bottom: 26px;

        input {
            margin-top: 13px;
        }

        .Dropdown-root {
            width: 190px;
            margin-right: 0px;
        }

        .Dropdown-control, .Dropdown-menu {
            background-color: white;
        }

        .Dropdown-control {
            height: 56px;
        }

        .Dropdown-menu {
            margin-top: 0px;
        }

        .Dropdown-option, .Dropdown-control {
            @include text-shorten;
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }

    @media (min-width: 1580px) {
        margin-left: 30px;
        padding-left: 30px;

        .input-outer {
            input {
                margin-top: 0px;
            }
        }
    }
}

.content-editor__title {
    margin-bottom: 11px;

    font-size: 14px;
}

.content-editor__subtitle {
    margin-bottom: 11px;
}

.content-editor__item {
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        font-size: 14px;
    }

    &:nth-child(4) {
        .input-outer {
            &:first-child {
                width: 100%;

                @media (min-width: 780px) {
                    width: 66%;
                }
            }

            &:last-child {
                width: 100%;

                @media (min-width: 780px) {
                    width: 34%;
                }
            }
        }
    }

    .input-outer {
        width: 100%;

        margin-bottom: 20px;

        &:last-child {
            margin-right: 0px;
        }

        input {
            width: 100%;

            height: 30px;
            border: none;
            background-color: white !important;
        }

        button {
            height: 30px;
        }

        @media (min-width: 780px) {
            margin-bottom: 13px;
            margin-right: 15px;
        }
    }

    @media (min-width: 780px) {
        flex-direction: row;
    }
}

.content-editor__details {
    width: 100%;
    min-height: 302.5px;

    //margin-top: 31.5px;

    flex-direction: column;

    @media (min-width: 1580px) {
        flex-direction: row;
    }
}

.content-editor__tabs {
    display: flex;
    flex-direction: column;
}

.editor-tab {
    flex-direction: column;

    @include tablet {
        flex-direction: row;
    }
}

.editor-preview {

}

.content-editor__preview {
    @include tablet {
        margin-left: 54px;
    }
}

.tab-selector {
    @include flex-center;

    button {
        margin-right: 10px;

        &:last-child {
            margin-right: 0px;
        }
    }
}
