.modify-member {}

.modify-member__info {
    margin-top: 55.2px;
    font-size: 16px !important;

    p {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.modify-member__job-role {
    margin-bottom: 14px !important;
    
    margin-top: 0px !important;
}

.modify-member__checkboxes-outer {
    display: flex;
    flex-direction: column;

    margin-top: 58px;
}

.modify-member__buttons-outer {
    display: flex;
    flex-direction: column;

    margin-top: 53px;

    button {
        width: 100%;
    }
}

.modify-member__buttons {
    margin-bottom: 20px;

    button {
        margin-right: 31px;

        &:last-child {
            margin-right: 0px;
        }
    }

    @include flex-row-centered;
}

.modify-member__checkboxes {
    margin-top: 40px;

    max-width: 500px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    .checkbox {
        font-size: 13px !important;
    }
}