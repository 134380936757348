.editor-gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 32px;
    padding: 16px;

    background-color: white;

    @media (max-width: 1900px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1575px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1240px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 736px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.gallery-item {
    height: 113px;

    border-radius: 10px;
    background-color: #EFF2F4;

    flex-direction: column;
    background-size: cover !important;
    cursor: pointer;

    img {
        width: 36px;
        height: 36px;

        margin-bottom: 18px;

        filter: invert(44%) sepia(1%) saturate(1956%) hue-rotate(50deg) brightness(99%) contrast(94%);
    }

    p {
        color: #707070;
        font-size: 12px;
    }

    &:hover {
        background-color: #D2DBE2;

        .gallery-item__hover {
            opacity: 1;
        }

        .gallery-item__content {
            display: none;
        }
    }
}

.gallery-item__content {
    width: 100%;
    height: 100%;

    flex-direction: column;

    @include flex-center;
}

.gallery-item__hover {
    display: flex;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: $color-pink;
}

.gallery-item--dotted {
    background-color: transparent;
    border: 3px dotted #c1c1c1;
    box-sizing: border-box;

    @include flex-center;

    img {
        width: 25px;
        height: 25px;
    }
}
