.results-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.results-list__head {
    display: none;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-right: 40px + 13px;
    box-sizing: border-box;
    margin-bottom: 20px;
    @include phone {
        display: flex;
    }

}

.results-list__header-secondary {
    width: 100%;
    max-width: $result-list-item-meta-width * 2;
    display: flex;

}

.results-list__title {
    padding-left: $result-list-item-padding;
    &:first-child {
        width: 100%;
        max-width: initial;
    }
    width: 100%;
    max-width: $result-list-item-meta-width;
}


.results-list__items {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 10px;
}
