.dashboard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.dashboard__main {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.dashboard__top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 75px;
    flex-wrap: wrap;
    justify-content: center;
    .image-button {
        margin-left: 20px;
        margin-bottom: 20px;
    }

    @include desktop {
        justify-content: flex-start;

    }
}

.dashboard__title {
    margin-bottom: 40px;


}

.dashboard__row-title {
    color: $color-medium-grey;
    margin-right: 20px;

    display: none;
    @include desktop {
        display: block;
    }
}

.dashboard__col {
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
    @include tablet {
        width: 100%;
    }
    @include desktop {
        width: 50%;
    }
    @include desktop-extra-large {
        width: 33.3%;
    }
}

.dashboard__col-title {
    font-size: 24px;
}

.dashboard__col-action {
    font-size: 16px;
    color: $color-blue;
}


.dashboard__col-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;


}

.dashboard__col-body {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

