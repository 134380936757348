
.user-roles__outer {
    margin-top: 50px;
    margin-bottom: 50px;
}

.user-roles {
    margin-top: 46px;

    @include flex-row-centered;

    .checkbox {
        margin-right: 46px;

        &:last-child {
            margin-right: 0px;
        }
    }
}
