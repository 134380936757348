.blue-arrow-button {
    background-color: $color-blue;
    color: #FFFFFF;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: -15px;
    margin-top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: none;
    transition: 0.4s;
    &:hover {
        cursor: pointer;
        background: darken($color-blue, 20%);
    }

}
