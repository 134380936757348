.error {
    display: flex;
    flex-direction: row;

    height: 100vh;
}

.error__inner {
    width: 100%;

    height: 100%;

    box-sizing: border-box;
    padding: 36px;

    flex-direction: column;

    @include flex-center;

    @include phone {
        width: 50%;
    }
}

.error__content {
    display: flex;
    flex-direction: column;

    max-width: 374px;
    width: 100%;

    margin: auto;
    overflow-y: auto;

    h1 {
        font-size: 50px;
    }

    b {
        margin: 31px 0px;
        font-size: 20px;

        color: #EA1557;
    }

    .button {
        min-height: 56px;
    }
}

.error__title {
    font-weight: 400;
    font-size: 40px !important;
    margin-bottom: 100px;

    @include tablet {
        font-size: 60px !important;
    }
}

.error__primary {
    font-size: 20px;
    font-weight: 300;
}

.error__secondary {
    margin: 30px 0px;

    font-size: 14px;
}

.error__additional {
    color: #00C8C3;

    font-size: 12px;

    margin-bottom: 40px;
}

.error__image {
    height: 100%;

    display: none;

    background-color: #EFF2F4;

    background-image: url($error-image);
    background-size: cover;

    @include phone {
        width: 50%;
        display: block;
    }
}

.error__links {
    @include flex-row-space-between;

    margin-top: 30px;

    a {
        font-size: 14px;
        color: #FF7612;
    }
}
