@mixin flex-row-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-row-baseline {
    display: flex;
    flex-direction: column;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@mixin custom {
    @media (min-width: $screen-custom) {
        @content;
    }
}

@mixin boxShadow {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

@mixin desktop-extra-large {
    @media (min-width: $screen-desktop-extra-large) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: $screen-desktop-large) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $screen-desktop) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $screen-tablet) {
        @content;
    }
}

@mixin large-phone {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin phone {
    @media (min-width: $screen-phone) {
        @content;
    }
}

@mixin phone-small {
    @media (min-width: $screen-phone-small) {
        @content;
    }
}

@mixin text-shorten {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin centerX {
    position: absolute;

    transform: translateX(-50%);

    left: 50%;
}