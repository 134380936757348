.document-upload__content {
    gap: 75px;
    display: flex;
    flex-wrap: wrap;
}

.document-upload__outer {
    display: flex;
}

.document-upload__container {
    display: flex;
    flex-direction: column;

    gap: 25px;

    max-width: 115px;
}

.document-upload {
    width: 115px;
    height: 115px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;

    border: 1px dotted #C6C7D1;
    border-spacing: 2px;

    &--successfull {
        border: 1px solid $color-green;
    }
}

.document-upload__file {
    font-size: 12px;
    color: #707070;
}

.document-upload__inner {
    display: flex;
    flex-direction: column;

    margin-left: 46px;

    gap: 15px;
}

.document-upload-inner__text {
    font-size: 14px;
    color: #929BA8;
}