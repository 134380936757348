$border-color: #f2f2f2;

$theme-grey: #F5F8FA;

$color-blue: #1577EA;
$color-blue-hover: #0C3B99;

$color-pink: rgb(255, 53, 86);

$color-orange: #FF7612;
$color-orange-hover: #E85900;

$color-red: #ED2855;
$color-red-hover: #D00D3A;

$color-grey : #929BA8;
$color-grey-hover: #354859;

$color-light-grey: #C6C7D1;
$color-light-grey-hover: #61707F;

$color-dark-grey: #61707F;
$color-dark-grey-hover: #354859;

$color-darker-grey: #354859;
$color-darker-grey-hover: #0C2435;

$color-dark: #0C2435;

$color-green: #00C8C3;
$color-green-hover: #00A6A2;

$color-medium-grey : #D2DBE2;

$link-hover: rgb(240, 240, 240);
$link-active: rgb(230, 230, 230);

$sidebar-width: 183px;

$screen-custom: 960px;

$screen-desktop-extra-large: 1400px;
$screen-desktop-large: 1200px;
$screen-desktop: 992px;
$screen-tablet: 968px;
$screen-phone: 576px;
$screen-phone-small: 390px;

$button-inactive: #FF7612;
$button-confirm: #00C8C3;
$button-idle: #D2DBE2;

$border-radius: 20px;

$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$item-background: #F5F8FA;

$register-image: "/assets/registration-screen/DMC-registration.jpg";
$login-image: "/assets/login-screen/DMC-login-screen.jpg";
$error-image: "/assets/error-screen/DMC-Error-Screen.jpg";
$background-image: "https://cdn.dribbble.com/users/1869172/screenshots/15264630/media/d38b8f4afce15c2c9e4226df84ea849a.png?compress=1&resize=1200x900&vertical=top";

