.user-dropdown {
    position: absolute;

    top: 61px;
    left: 0px;
    width: 100%;

    flex-direction: column;

    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px;
    border-radius: 0px 0px 20px 20px;

    padding: 30px 0px;

    z-index: 99999999999999999;

    overflow: hidden;

    a {
        text-decoration: none;
        color: black;

        font-size: 14px;
        font-weight: 400;
    }

    @include desktop {
        width: 337px;

        top: 61px;
        right: 55px;
        left: unset;
    }
}

.user-dropdown__item {
    height: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 15px 29px;

    cursor: pointer;
    
    @include desktop {
        &:first-child {
            display: none !important;
        }
    }

    h3 {
        font-size: 20px;
        font-weight: 400;
    }

    p {
        font-size: 12px;
    }

    &:last-child {
        margin-bottom: 0px;
    }

    &:active {
        background-color: $link-active !important;
    }

    &:hover {
        background-color: $link-hover;
    }

    &:first-child {
        &:hover {
            background-color: transparent;
        }

        &:active {
            background-color: transparent !important;
        }
    }
}