.dropdown {
    background-color: white;

    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.161));
}

.dropdown__head {
    padding: 15px 21px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
}

.dropdown-head__icon {
    width: 14px;
    height: 14px;
}

.dropdown__toggle {
    width: 50px;
    height: 50px;

    border: 1px solid #0C2435;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: transform .3s linear;
}

.dropdown__content {
    
}