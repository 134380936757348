.sponsors__outer {
    display: flex;
    align-items: center;

    flex-wrap: wrap;

    img {
        margin-right: 20px;

        &:last-child {
            margin-right: 0px;
        }
    }

    p {
        max-width: 100px;
        color: #354859;
        font-size: 10px;
        margin-right: 42px;
    }
}