$result-list-item-meta-width : 252px;
$result-list-item-padding : 13px;

.results-list-item {
    width: 100%;

    border: 1px solid  #C6C7D1;
    border-radius: 20px;

    background-color: white;

    padding: $result-list-item-padding;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;

    gap: 20px;

    @include phone {
        align-items: center;
        flex-direction: row;

        height: 74px;
        padding: 0px 13px;
    }
}

.results-list-item__icon {
    margin-right: 13px;
}

.results-list-item__title {
    font-size: 14px !important;
    color: black !important;
}

.results-list-item__id {
    color: $color-blue !important;
}

.results-list-item__info {
    align-items: flex-end;
}

.results-list-item__text {
    display: flex;
    flex-direction: column;

    gap: 6.25px;

    white-space: nowrap;

    p {
        color: #929BA8;
        font-size: 12px;
    }
}

.results-list-item__details {}

.results-list-item__main {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.results-list-item__metas {
    height: 100%;
    display: flex;
    flex-direction: row;

    align-items: center;

    border-top: 1px solid #C6C7D2;
    border-bottom: 1px solid #C6C7D2;

    padding: 10px 0px;

    margin-top: 10px;
    text-transform: capitalize;




    @include phone {
        border: unset;

        padding: 0px;

        margin-top: 0px;
        width: initial;
    }
}
.results-list-item__metas.results-list-item__metas--with-one.results-list-item__metas--with-two {
    max-width: $result-list-item-meta-width * 2;
    margin-left: auto;
    width: 100%;
}

.results-list-item__meta {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: $result-list-item-meta-width;
    &:last-child {
        border-left: 1px solid #C6C7D2;
    }

    p {
        color: #BEC0CB;
        font-size: 12px;
    }

    @include phone {
        height: 100%;

        display: flex;
        align-items: center;


        padding: 0px 10px;

        border-left: 1px solid #C6C7D2;
    }
}

.results-list-item__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    @include phone {
        margin-top: 0px;
        max-width: 40px;
    }
}


.results-list-item-meta-info {
    max-width: $result-list-item-meta-width;
    width: 100%;
    min-width: 100px;
    
    p {
        font-style: italic;
        color: #BEC0CB;
        font-size: 12px;
    }

    text-align: right;
}


