.inputs-text {
    width: 100%;
    height: 45px;

    border: 1px solid #D2DBE2;
    outline: none;

    border-radius: 20px;

    font-size: 14px;
    background-color: #F5F8FA;
    box-sizing: border-box;

    padding: 0px 15px;

    &:read-only {
        background-color: #FFFFFF !important;
        border: none;
        padding-left: 0px;
    }
}

.inputs-textarea {
    width: 100%;
    height: 120px;

    border: 1px solid #D2DBE2;
    outline: none;

    border-radius: 20px;

    font-size: 14px;
    background-color: #F5F8FA;
    box-sizing: border-box;

    padding: 15px;

    &:read-only {
        background-color: #FFFFFF !important;
        border: none;
        padding-left: 0px;
    }
}