.editor-preview-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.editor-preview-top__actions {
    display: flex;
    justify-content: flex-end;
    div {
        margin-left: 20px;
        &:hover {
            cursor: pointer;
        }
    }
}


.editor-preview {
    width: 100%;
    height: 700px;
    background: #FFFFFF;
    overflow-x: scroll;
    border-radius: 20px;
    box-shadow: $box-shadow;

    iframe {
        border: none;
        border-radius: 20px;
        width: 133%;
        height: 100%;
        min-height: 1800px;
        zoom: 0.7;
        -moz-transform: scale(0.75);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.75);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.75);
        -webkit-transform-origin: 0 0;
    }

    &--mobile {
        width: 30%;
        margin: auto;
    }

    &--tablet {
        width: 50%;
        margin: auto;
    }
}
