.activity-list {
    position: relative;

}

.activity-list__inner {
    max-height: 600px;
    overflow-y: auto;
    padding-bottom: 100px;
}

.activity-list__overflow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(0deg,  rgba(255,255,255, 1),  rgba(255,255,255, 0));
}
