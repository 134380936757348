.search-box__outer {
    display: flex;
    flex-direction: column;
}

.search-box {
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;

    box-sizing: border-box;

    height: 56px;

    padding: 0px 15px;
    border-radius: $border-radius;

    margin-top: 20px;

    input {
        width: calc(100% - 30px);

        outline: none;
        border: none;
    }

    &--toggled {
        border-radius: $border-radius $border-radius 0px 0px;
    }
}

.search-box__content {
    box-sizing: border-box;
    padding: 15px;
    background-color: white;

    border-radius: 0px 0px $border-radius $border-radius;
}