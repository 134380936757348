.sticky-container.sticky {
    .tab-bar__bar {
        border-radius: 20px 20px 20px 20px;
    }
}

.topbar__logo {
    height: 40px;
}

.topbar-responsive {
    width: 100%;
    height: 60px;

    padding: 0px 36px;
    box-sizing: border-box;

    display: flex;

    justify-content: space-between;

    @include flex-row-centered;

    @include desktop {
        display: none !important;
    }
}

.topbar-responsive__icon {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    &:last-child {
        margin-left: 20px;
    }
}

.topbar-responsive__inner {
    @include flex-row-centered;
}

.topbar {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #EFF2F4;

    position: fixed;

    background-color: #ffffff;
    z-index: 100;
}

.topbar__content {
    width: 100%;

    visibility: hidden;

    display: flex;

    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

    @include desktop {
        visibility: visible;
    }
}

.topbar__head {
    min-width: $sidebar-width;
    width: 100%;
    max-width: $sidebar-width;
    height: 60px;
    border-right: 1px solid $border-color;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ffffff;

    h1 {
        font-size: 24px;
        font-weight: 500;
    }

    @include tablet {

    }
}

.topbar__left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
}

.topbar__right {
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding-right: 55px;

    @include custom {
        width: 100%;
        justify-content: flex-end;
    }
}

.topbar__inner {
    width: 337px;

    position: relative;

    @include flex-row-centered;

    @include custom {
        width: fit-content;
    }

    @include tablet {
        position: none;
    }
}

.topbar-menu__icon {
    min-width: 30px;
    height: 30px;

    border-radius: 50%;
    box-shadow: 0px 0px 5px #A4C9F4;

    transition: .2s;

    user-select: none;

    @include flex-center;

    img {
        transform: rotate(90deg);
    }

    &--toggled {
        transform: rotate(180deg);
    }

    &:hover {
        cursor: pointer;
    }

    @include custom {
        margin-left: 0px;
    }
}

.notifications__menu {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;
}

.user-info {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 47px;

    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 20px;
    }
}

.user-info__inner {
    margin-left: 10px;

    display: none;

    h4 {
        font-size: 16px;
        font-weight: 500;
        max-width: 200px;
    }

    p {
        font-size: 12px;
        color: #707070;
    }

    @include text-shorten;

    @include custom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
