.state {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.states-empty {
    min-height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 20px;
}

.states-empty__title {
    font-size: 20px !important;
}

.states-empty__description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}

.states-empty__head {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
