.distributions-settings {
    margin-top: 40px !important;

    width: 100%;
    max-width: 1700px;
    margin: auto;
}

.distribution-settings__row {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;

    .input-outer {
        width: 100%;

        margin-right: 26px;

        margin-bottom: 24px;
        
        input {
            background-color: #F5F8FA;
        }

        &:last-child {
            margin-right: 0px;
        }

        @include tablet {
            margin-bottom: 0px;
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }

    @include tablet {
        flex-direction: row;
    }
}

.distributions-settings__title {
    font-size: 16px;
    margin-bottom: 23.5px;
}

.distributions-settings__section {
    padding-bottom: 40px;
    margin-top: 40px;
    border-bottom: 1px solid #D2DBE2;

    &:first-child {
        margin-top: 0px;
    }

    &:last-child {
        border-bottom: 0px;
    }
}