.content {
    .side-popup {
        top: 61px;
    }

    .side-popup__outer {
        top: 0px;
    }
}

.side-popup {
    position: fixed;

    width: 100%;

    height: calc(100% - 60px);

    border-left: 1px solid $border-color;


    z-index: 1;

    background-color: #F5F8FA;

    overflow-y: auto;

    visibility: visible;

    top: 61px;
    right: 0px;

    @include phone {
        width: 75%;
    }

    @include desktop {
        width: 800px;
        height: calc(100% - 60px);
    }
}

.side-popup__title {
    font-size: 30px !important;
    font-weight: 400;
}

.side-popup__close {
    font-size: 16px;
    color: #151515;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
}

.side-popup__outer {
    position: absolute;

    top: 61px;
    left: 0px;

    width: 100%;
    height: 100vh;

    z-index: 99;

    background-color: transparentize(#929BA8, 0.3);
}

.side-popup__head {
    width: 100%;

    box-sizing: border-box;

    padding: 35px 52px;

    background-color: white;
}

.side-popup__content {
    box-sizing: border-box;

    padding: 35px 52px;

    display: flex;
    flex-direction: column;

    gap: 29px;

    .utils__row {
        align-items: unset;
    }
}

.side-popup__section {
    border-bottom: 1px solid #D2DBE2;
    padding-bottom: 20px;

    display: flex;
    flex-direction: column;

    gap: 20px;

    label {
        font-size: 12px;
    }

    .input-outer, .textarea {
        //width: 100%;
    }

    &:last-child {
        border: none;
    }
}

.side-popup-section__title {
    color: #929BA8;
}

.side-popup-head__text {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    margin-top: 20px;
}

.side-popup__back {
    margin-bottom: 36px;

    font-size: 16px;

    cursor: pointer;

    img {
        filter: invert(100%);
        transform: rotate(180deg);

        margin-right: 13.4px;
    }

    @include flex-row-centered;
}

.side-popup-content__section {
    display: flex;
    flex-direction: column;

    gap: 20px;

    p {
        font-size: 12px;
        color: #929BA8;
    }
}