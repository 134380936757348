.grants-preview-status-bar {
    position: fixed;
    width:100%;
    top: 0;
    color: black;
    display: flex;
    justify-content: center;
    top: -20px;

    transition: 1s;

    &.new-session {
        .grants-preview-status-bar__inner {
            background: $color-red;
        }
    }

    &.change  {
        .grants-preview-status-bar__inner {
            background: $color-orange;
        }
    }



    &.open {
        top: 0px;
        .grants-preview-status-bar__actions {
            opacity: 1;
        }
    }

}

.grants-preview-status-bar__inner {
    max-width: 1400px;
    width: 100%;
    position: relative;
    display: flex;
    background: $color-green;
    padding: 40px;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.grants-preview-status-bar__actions {
    opacity: 0;
    transition: 1s;
}

.grants-preview-status-bar__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.grants-preview-status-bar__icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    img {
        width: 40px;
        height: 40px;
    }
}
