.notification-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;

    padding: 0px 18px 0px 28px;

    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    cursor: pointer;

    transition: 0.6s;

    .notification-item__icon {
        transition: 0.4s;
    }
    &.scale {
        .notification-item__icon {
            transform: scale(1.2);
        }
    }

    &--toggled {
        background-color: $color-orange !important;
    }

    &--read {
        background-color: white !important;

        .notifications-item__date, .notifications-item__type {
            color: $color-blue;
        }

        .notifications-item__button {
            background-color: $color-blue;
            color: #FFFFFF;
        }

        .notifications-item__name, .notifications-item__description {
            color: #000000;
        }
    }
}

.notification-item__icon {
    width: 30px;
    height: 30px;

    border-radius: 50%;

    background-color: $color-blue;

    margin-left: -15px;
    margin-top: -15px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    color: white;
}

.icon__notication {
    width: 23.68px;
    height: 29.32px;
}

.icon__messages {
    width: 31.64px;
    height: 23.82px;
}

.notifications-responsive {
    @include flex-row-centered;

    background-color: #ffffff;

    border-top: 1px solid $border-color;

    width: 100%;
    height: 60px;

    position: fixed;

    bottom: 0px;

    display: flex;

    z-index: 100;

    .notification-item {
        width: 100%;

        justify-content: center;
    }

    @include desktop {
        display: none;
    }
}
