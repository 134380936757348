.tags-input {
    &__control {
        border-radius: $border-radius !important;
        border: none !important;
    }

    &__indicator {
        cursor: pointer;
        padding: 0px;
        padding-right: 8px;
    }
}
.tags-input__multi-value__remove {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    &:hover {
        cursor: pointer;
    }
}

.editor-dropdown {
    box-shadow: $box-shadow;

    margin-bottom: 10px;

    .editor-dropdown {
        box-shadow: none;

        .editor-dropdown__inner {
            background-color: white;

            padding: 9px;
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }
    transition: 0.2s;
    &--highlighted {
        box-shadow: 0 0 10px $color-blue;
    }

    .editor-dropdown {
        &--highlighted {
            box-shadow: 0 0 2px $color-blue;
        }
    }
}


.editor-dropdown__items {
    @include flex-row-centered;
}

.editor-dropdown__arrow {
    width: 30px;
    height: 30px;

    border-radius: 50%;

    margin-right: 20px;

    cursor: pointer;

    img {
        margin-right: 0px !important;
    }

    @include flex-center;
}

.editor-dropdown__title {
    margin-bottom: 10px;
    font-size: 10px;
}

.editor-dropdown__delete, .editor-dropdown__draghandle {
    cursor: pointer;

    &:active {
        cursor: pointer !important;
    }
}

.editor-dropdown__bar {
    width: 100%;
    height: 46px;

    padding: 15px 13px;

    user-select: none;

    box-sizing: border-box;

    background-color: #FFFFFF;



    &:hover {
        background-color: #bebebe;
    }

    p {
        text-transform: capitalize;
    }

    div {
        &:first-child {
            @include flex-row-centered;

            img {
                margin-right: 17px;
            }
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    @include flex-row-space-between;
}

.editor-dropdown__delete {
    width: 40px;
    height: 40px;

    &:active {
        filter: invert(50%);
    }
}

.editor-dropdown__inner {
    background-color: #F7F7F7;

    padding: 9px;
    box-sizing: border-box;

    &--noPadding {
        padding: 0px !important;
    }

    .cm-editor, .tox-tinymce {
        height: 300px !important;
    }
}

.displayable {
    padding: 10px 14px;
    border-radius: $border-radius;

    background-color: $button-confirm;
    font-size: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    img, b {
        margin-left: 8.8px;
    }

    &--not {
        background-color: $button-idle;
    }
}
