.popup {
    width: 100%;
    height: 100vh;

    position: fixed !important;

    display: flex;

    top: 0px;
    left: 0px;

    z-index: 2998;

    backdrop-filter: blur(30px);

    overflow-y: auto;
    padding: 50px 0px;
    box-sizing: border-box;

    @include desktop {
        height: 100vh;
    }

    .tab-bar__bar {
        margin-top: 0px;
    }
}

.images-popup {
    .popup__close {
        display: none;
    }
}

.popup__inner {
    width: 100%;
    max-width: 758px;
    padding: 50px 36px;

    margin: auto;

    display: flex;
    flex-direction: column;

    gap: 26px;

    h1 {
        font-size: 30px;
        font-weight: 400;
    }

    .input-outer {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.popup__content {
        font-size: 20px;
    margin-bottom: 50px;
    font-weight: 400;
}

.popup__buttons {
    @include flex-row-centered;

    flex-direction: column;

    button {
        width: 100%;

        margin-bottom: 20px;
        margin-top: 0px !important;

        &:last-child {
            margin-bottom: 0px;
        }

        @include desktop {
            margin-right: 10px;
            margin-bottom: 0px;

            &:last-child {
                margin-right: 0px
            }
        }
    }

    @include desktop {
        flex-direction: row;
    }
}

.popup__close {
    position: absolute;
    top: 50px;
    right: 50px;
    background: #61707F;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    cursor: pointer;
}
