.list-card {
    border-radius: 20px;
    background: $theme-grey;
    margin-bottom: 20px;
    padding: 20px 40px 20px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.list-card__content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.list-card__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 30px;
    margin-left: 40px  ;
}

.list-card__top {
    color: $color-blue;
    margin-bottom: 10px;
    font-size: 10px;
    text-transform: capitalize;
}

.list-card__title {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    word-break: break-word;
}

.list-card__body {
    font-size: 12px;

}


