.sticky-container {
    &.sticky {

       .sticky-container__inner {
               position: fixed;

           //padding-top: 76px;


           z-index: 99;


           @include large-phone {
               padding-top: 0px;
           }
       }
        .state-pages__header, .state-pages__top  {
            padding-top: 20px;
            background: white;
        }
        .state-pages__navigation-pills {
            background: white;
        }
    }

    &.with-offset.sticky {
        padding-top: 0px;
        .sticky-container__inner {
            z-index: 98;
        }
    }


}


.sticky-container__spacer {
    opacity: 0;
}
