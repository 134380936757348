.notifications-popup {
    position: fixed;

    border-right: 1px solid $border-color;

    svg {
        margin-bottom: 20px;
    }
}

.notifications-popup__head {
    @include flex-row-space-between;
}

.notifications-popup__start {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.notifications-popup__icon {
    width: 30px;
    height: 30px;

    border-radius: 50%;

    background-color: $color-blue;

    font-size: 12px;
    color: white;

    margin-left: 45px;

    @include flex-center;
}

.notifications-popup__links {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    p {
        text-decoration: underline;

        &:first-child {
            color: $color-blue;
            margin-bottom: 9px;
        }
    }
}

.notifications-popup__content {
    margin-top: 32px;

    overflow-y: auto;
}

.notifications-popup__link {
    &:hover {
        cursor: pointer;
    }
}

.notifications-item {
    background-color: $color-blue;
    border-radius: $border-radius;

    box-sizing: border-box;
    padding: 20px;

    display: flex;
    flex-direction: row;

    margin-bottom: 20px;

    img {
        width: 40px;
        height: 40px;
    }

    &--messages {
        background-color: $color-orange;
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.notifications-item__info {
    width: 100%;
    margin-left: 24px;

    p {
        color: white;
    }
}

.notifications-item__date, .notifications-item__type, .notifications-item__description {
    font-size: 12px;
}

.notifications-item__name {
    font-size: 16px;
    margin-top: 9px;

    text-transform: capitalize;
}

.notifications-item__type {
    color: #D2DBE2;

    white-space: nowrap;
}

.notifications-item__button {
    width: 30px;
    height: 30px;

    border-radius: 50%;

    background-color: #ffffff;
    color: $color-blue;

    outline: none;
    border: none;

    margin-top: 10px;

    cursor: pointer;

    @include phone {
        margin-top: 0px;
    }

    @include flex-center;
}

.notifications-item__bar {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    @include phone {
        flex-direction: row;
        align-items: center;
    }

    &:last-child {
        margin-top: 12px;
    }
}
