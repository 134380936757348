.free-content-buttons {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 32px;
    padding: 16px;
    margin-bottom: 17px;

    background-color: white;

    @media (max-width: 1900px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1575px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1240px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 736px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.free-content-button {
    height: 113px;

    border-radius: 10px;
    background-color: #EFF2F4;

    flex-direction: column;

    cursor: pointer;

    img {
        width: 36px;
        height: 36px;

        margin-bottom: 18px;

        filter: invert(44%) sepia(1%) saturate(1956%) hue-rotate(50deg) brightness(99%) contrast(94%);
    }

    p {
        color: #707070;
        font-size: 12px;
    }

    &:hover {
        background-color: #D2DBE2;
    }

    @include flex-center;
}