.account {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1638px;

    margin: auto;

    .website-row {
        margin-bottom: 35px;

        input {
            background-color: #F5F8FA;
        }

        .input-outer {
            margin-bottom: 33px;
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }

    @include desktop-large {
        .website-row {
            display: flex;
            flex-direction: row;

            .input-outer {
                width: 100%;
                margin-right: 10px;
                margin-bottom: 0px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}


.account__block {
    display: flex;
    flex-direction: column;

    @include desktop-large {
        flex-direction: row;
    }

    &--full-width {
        width: 100%;
    }
}

.account__user {
    display: flex;

    flex-direction: column;
    align-items: center;

    @include tablet {
        flex-direction: row;

        margin-bottom: 57px;
    }
}

.account__information {
    height: fit-content;

    box-sizing: border-box;

    padding: 33px 28px;

    background-color: #F5F8FA;
    border-radius: 20px;
    border: 1px solid #EFF2F4;
    width: 100%;

    margin-left: 0px;
    margin-top: 33px;

    @include desktop-large {
        width: 520px;
        min-width: 520px;

        margin-left: 50px;

        &--full-width {
            width: 100% !important;
            min-width: 0px !important;

            margin-left: 0px !important;
        }
        &--centered {
            width: 100%;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.account__information-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}

.account__information-body {

}

.information__date {
    margin-top: 20px;
}

.account__inputs {
    width: 100%;

    display: flex;
    flex-direction: column;
}

.account__section {
    padding-top: 36px;

    border-bottom: 1px solid $border-color;

    p {
        font-size: 16px;
        margin-bottom: 36px;
    }

    &:first-child {
        padding-top: 0px;
    }

    &:last-child {
        padding-bottom: 0px;

        border-bottom: none;
    }

    @include desktop-large {
        padding-bottom: 36px;
    }

    &--full-width {
        width: 100%;
    }
}

.account__user-icon {
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.account__column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 0px;

    align-items: center;

    a {
        color: #D2DBE2;
        font-size: 14px;
        margin-top: 23px;
    }

    @include tablet {
        margin-left: 40px;
        align-items: flex-start;
    }
}

.account__roles {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
    margin-bottom: 40px
}

.account-role {
    background-color: #00C8C3;
    word-break: break-word;
    padding: 22px;
    border-radius: 20px;

    h2 {
        color: white;
        font-size: 20px;
    }

    p {
        color: #1577EA;
        font-size: 12px;
        margin-top: 10px;
    }

    b {
        color: #ffffff;
        margin-top: 7px;
        font-size: 12px;
    }

    &:last-child {
        background-color: #000000;
        margin-right: 0px;
    }
}
