::-webkit-scrollbar {
    display: none;
}

.tox-tinymce {
    width: 100%;
    min-height: 300px;
}

#tawk {
    border-radius: 20px;
    overflow: hidden;
    max-width: 350px;
    border: 2px solid #ebebeb;
}


.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.state-pages__navgiation {
    color: $color-blue;
    font-size: 16px;

    display: flex;
    align-items: center;

    cursor: pointer;

    svg {
        margin-left: 10px;
    }

    &--hidden {
        opacity: 0 !important;
        cursor: unset;
    }

    &--background {
        background-color: $color-blue;
        height: 37px;
        padding: 0px 15px;
        border-radius: 20px;
        color: white !important;

        svg path {
            fill: white;
        }
    }

    &--backgroundDisabled {
        background-color: #C6C7D2;
        height: 37px;
        padding: 0px 15px;
        border-radius: 20px;
        color: white !important;

        user-select: none !important;
        cursor: unset !important;

        opacity: 0.4;

        svg path {
            fill: white !important;
        }
    }

    &--disabled {
        color: #C6C7D2 !important;
        user-select: none !important;
        cursor: unset !important;

        svg path {
            fill: #C6C7D2;
        }
    }
}

.state-pages__next {
    color: $color-blue;
    font-size: 16px;

    display: flex;
    align-items: center;

    cursor: pointer;

    svg {
        margin-left: 10px;
    }

    &:hover {
        img {
            filter: brightness(0) saturate(100%) invert(35%) sepia(25%) saturate(4446%) hue-rotate(195deg) brightness(98%) contrast(96%);
        }
    }
}

.state-pages__back {
    color: $color-blue;
    font-size: 16px;

    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    cursor: pointer;

    svg {
        margin-right: 10px;
        transform: rotate(180deg);
    }

    &:hover {
        img {
            filter: brightness(0) saturate(100%) invert(35%) sepia(25%) saturate(4446%) hue-rotate(195deg) brightness(98%) contrast(96%);
        }
    }
}

.Dropdown-root {
    margin-right: 10px;

    width: 372.09px;

    .Dropdown-control {
        background-color: $button-idle;
        border-radius: 20px;

        height: 56px;
        text-transform: capitalize;

        padding: 9.25px 20px;

        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .Dropdown-placeholder {
            @include text-shorten;
        }
    }

    .Dropdown-option {
        white-space: nowrap;

        padding: 10px 22px !important;

        text-transform: capitalize;
    }

    .Dropdown-menu {
        background-color: $button-idle;
        border-radius: 20px;

        width: 100%;
        max-height: 150px;

        overflow-y: auto;

        margin-top: 5px;
    }

    .Dropdown-arrow {
        top: unset;
        right: unset;
    }
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: Inter;
}

.website {
    display: flex;
    flex-direction: column;

    overflow: hidden;
}

.website__content {
    margin-top: 30px;

    padding-top: 30px;

    border-top: 1px solid $border-color;

    display: flex;
    flex-direction: column;
}

.checkbox {
    @include flex-row-centered;

    input {
        margin-right: 10px;

        width: 25px;
        height: 25px;
    }
}

.website-topbar__settings {
    @include flex-row-centered;

    color: #D2DBE2;
    font-size: 16px;

    img {
        margin-right: 15.5px;
    }
}

.website-topbar__filter {
    @include flex-row-centered;

    p {
        margin-right: 30px;
    }

    .checkbox {
        margin-right: 22px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.website__topbar {
    width: 100%;

    display: flex;
    align-items: start;
    flex-direction: column;

    @include desktop {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .website-row {
        width: unset;

        margin-top: 20px;

        @include flex-row-centered;

        @include desktop {
            margin-top: 0px;
        }

        button {
            margin-top: 0px;
            margin-right: 10px;

            &:last-child {
                margin-bottom: 0px;
                margin-right: 0px;
            }
        }
    }
}

.inner {
    width: 100%;
    height: 100%;

    position: relative;

    margin-top: 60px;

    display: flex;
    flex-direction: row;
}

.content {
    width: 100%;
    height: calc(100vh - 120px);
    padding: 36px;
    
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    overflow-y: auto;

    h1 {
        font-size: 30px;
        font-weight: 300;
    }

    @include desktop {
        width: calc(100% - 183px) !important;
        height: calc(100vh - 60px);
        margin-left: 183px !important;
    }
}

.markdown > * {
    margin: revert;
}

.markdown {
    ul, ol {
        padding-inline-start: 40px;
        list-style: revert !important;
    }
}

.full-width {
    width: 100%;
}