$application-activity-breakpoint: 1600px;

.application-activity {
    .results-list-item {
        @media (min-width: 1200px) and (max-width: $application-activity-breakpoint) {
            padding: 13px;
            height: unset;

            flex-direction: column;
        }


    }

    @include tablet {
        .results-list {
            max-height: 600px;
            overflow-y: auto;
        }
    }

    .results-list-item__metas {
        @media (min-width: 1200px) and (max-width: $application-activity-breakpoint) {
            width: 100%;
        }
    }

    .results-list-item-meta-info {
        @media (min-width: 1200px) and (max-width: $application-activity-breakpoint) {
            text-align: left;
            display: flex;
            align-items: center;

            gap: 10px;
            margin-left: 31px;
            margin-top: 5px;
        }
    }
}
