.grant-editor__info-bar {
    padding: 20px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-details  {
        margin-right: 20px;
    }
}



.grant-question-block {
    border-radius: 20px;

    &__head {
        background-color: #F9F9F9;

        border-radius: 20px;
    }

    &--toggled {
        border-radius: 20px 20px 0px 0px !important;
    }
}

.grant-editor-questions {
    margin: 25px 0px;
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.grant-question-block__top {
    display: flex;
    flex-wrap: wrap;

    gap: 10px 100px;

    background-color: #F9F9F9;

    padding: 0px 21px 21px 21px;

    border-radius: 0px 0px 20px 20px;

    .checkbox-round label {
        background-color: white;
    }
}

.grant-questions-block__block-mobile {
    p {
        color: #979CA0;
        font-size: 12px;
    }
}

.grant-question-block__top-item {
    flex-grow: 1;
}

.grant-question-block__top-item2 {
    flex-grow: 0.1;
}

.grant-question-block__questions {
    padding: 0px 21px;

    display: flex;
    flex-direction: column;

    gap: 20px;
}

.grant-question-block__content {
   display: flex;
   flex-direction: column;

   gap: 20px;

   padding: 20px 0px;

   .grant-question-block__content {
       padding: 0px;
   }
}

.grant-score-scores {
    display: flex;
    flex-wrap: wrap;

    gap: 20px;

    .input-outer {
        flex-grow: 1;
    }
}

.grant-editor__middle {
    @include flex-column;
}

.grant-editor__published {
    font-size: 10px;
    line-height: 16px;

    color: $color-darker-grey;
}
