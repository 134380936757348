.progress-popup {
    padding: 20px;
    box-sizing: border-box;

    background-color: white !important;

    width: 516px;

    .side-popup__close {
        width: 50px;
        height: 50px;
    
        background-color: #61707F;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        color: white;
    
        border-radius: 50%;
    }
}

.progress__head {
    display: flex;
    align-items: center;
    gap: 11px;
    font-size: 20px;

    margin-bottom: 10px;
}

.progress__title {
    font-size: 10px;
    color: #929BA8;
    margin-bottom: 10px;
}

.progress__inner {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.progress-item {
    background-color: white;
    border: 1px solid  #C6C7D1;
    border-radius: 10px;

    display: flex;

    gap: 20px;

    padding: 10px 20px;
    box-sizing: border-box;

    cursor: pointer;

    &--toggled {
        background-color: $color-green;
        border: none;

        img {
            filter: brightness(100);
        }

        .progress-item__text {
            p {
                color: white;
            }
        }
    }
}

.progress-item__text {
    display: flex;
    flex-direction: column;

    gap: 8px;
}

.progress-item-text__title {
    font-size: 14px;
    color: #151515
}

.progress-item-text__subtitle {
    font-size: 12px;
    color: #C6C7D2;
}

.progress__content {
    border-radius: 20px; 
    background-color: #F5F8FA;

    padding: 25px;

    box-sizing: border-box;

    margin-top: 35px;

    display: flex;
    flex-direction: column;

    gap: 15px;
}

