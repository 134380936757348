.request-reset {
    form {
        width: 400px;
    }

    .button {
        width: 100%;
    }

    .register__inner h1 {
        margin-bottom: 30px;
    }
}

.request-reset__error {
    margin-top: 20px;
    color: $color-pink;
}