.grant-question-types {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;

    margin: 0px 21px 21px 21px;
}

.question-type-section {
    display: flex;
    flex-wrap: wrap;

    gap: 30px 50px;

    background-color: #F9F9F9;

    border-radius: 0px 0px 20px 20px;
}

.grant-question-type, .question-type-icon {
    border-radius: 10px;

    background-color: white;
    border: 1px solid #929BA8;

    width: 132px;
    height: 113px;

    gap: 13.5px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    cursor: pointer;

    img {
        width: 36px;
        height: 36px;
    }

    p {
        color: #354859;
        text-align: center;
    }
}

.question-type {
    padding: 20px;
    box-sizing: border-box;

    flex-grow: 1;

    background-color: #F9F9F9;
}

.question-type__outer {
    display: flex;
    flex-wrap: wrap;
}

.question-type__head {
    display: flex;
    align-items: center;

    gap: 10px;

    img {
        width: 24px;
        height: 24px;
    }

    p {
        color: #151515;
    }
}

.question-type__content {
    margin-top: 23px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .utils__row .input-outer {
        flex-grow: 1;
    }

    .checkbox-round label {
        background-color: white;
    }
}

.question-type-field, .field {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.question-type-field__label {
    font-size: 12px;
    color: #354859;
}

.field__label {
    font-size: 16px;
    color: #929BA8;
}

.column__delete {
    margin-top: 35px;
    border-radius: 50%;
    background-color: #d1d1d1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}