.company-item {
    min-width: 100%;

    padding: 66px 28px;
    box-sizing: border-box;

    background-color: $item-background;

    border-radius: 20px;


    @include desktop {
        min-width: 300px;
    }
}

.company-item__row {
    @include flex-row-space-between;

    margin-bottom: 14px;

    p {
        color: $color-blue;

        &:last-child {
            color: $button-inactive;
        }
    }
}

.company-item__name {
    font-size: 16px;
}

.company-item__info {
    margin-top: 36px;
    margin-bottom: 33px;

    p {
        font-size: 12px;
        margin-top: 5px;
    }
}

.company-item__buttons {
    width: 100%;

    button {
        width: 100%;

        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}