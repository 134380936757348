.text-area {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.text-area__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;

    p {

        font-size: 14px;
        color: #354859;
    }
}

.text-area__error {

    font-size: 14px;
    color: $color-red !important;
}

.text-area textarea {
    background-color: white;

    box-sizing: border-box;

    height: 180px;


    padding: 25px;
    border-radius: 6px;

    border: 1px solid #707070;

    color: #61707F;

    font-family: Inter;
    font-size: 16px;

    &:focus {
        background-color: white;
    }
}
