.reset-password {
    .authentication__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button {
            margin-right: 10px;
    
            &:last-child {
                margin-right: 0px
            }
        }
    
        @media (max-width: 1250px) {
            flex-direction: column;
    
            button {
                width: 100%;

                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
    
            #forgot-password {
                margin-bottom: 33px;
            }
        }
    }
}

.your-password {
    font-size: 12px;
    margin-bottom: 33px;
}