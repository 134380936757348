.content-container {
    width: 100%;

    padding: 20px;
    border-radius: 20px;

    box-sizing: border-box;

    background-color: #F5F8FA;
}

.content-container__head {
    display: flex;
    justify-content: space-between;
}

.content-container__filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    gap: 20px;

    .select-dropdown__outer {
        //flex-grow: 1;
    }
}

.content-container__navigation {
    flex-direction: row;

    justify-content: center;
    align-items: center;

    gap: 52px;
}

.content-container-navigation__mobile {
    display: flex;
    flex-direction: column;

    gap: 20px;

    @media (min-width: 700px) {
        display: none !important;
    }
}

.content-container-navigation__desktop {
    display: none;

    @media (min-width: 700px) {
        display: flex !important;
    }
}

.row-gap {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 20px;
}

.content-container__child {
    margin: 20px 0px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.content-container__top {
    padding: 20px 0px;

    border-bottom: 1px solid #D2DBE2;
}

.content-container__child-split {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    border: none;
}

.content-container__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        font-size: 20px;
        margin: 0px 10px;
    }

    span {
        width: 30px;
        height: 30px;

        background-color: $color-blue;

        font-size: 12px;
        color: white;

        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
