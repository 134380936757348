.authentication {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: row;

    .input-outer {
        margin-bottom: 20px;

        p {
            margin-top: 20px;
            color: $color-pink;
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.authentication__login {
    .authentication__image {
        background-image: url($login-image) !important;
    }
}

.authentication__register {
    .authentication__image {
        background-image: url($register-image) !important;
    }
}

.authentication__image {
    width: 50%;
    height: 100%;

    background-image: url($background-image);
    background-size: cover;

    background-color: #EFF2F4;

    display: none;

    @include desktop {
        display: block;
    }
}

.authentication__inner {
    display: flex;
    flex-direction: column;
    margin: auto;

    overflow-y: auto;

    .barnsley {
        text-align: center;
        margin-top: 55px;
    }

    @include phone {
        padding-bottom: 36px;
    }
}

.authentication__title {
    font-size: 60px;
    font-weight: 400;

    margin-bottom: 91px;
    text-align: center;

    font-size: 38px;

    @include desktop {
        font-size: 50px;
    }
}

.authentication__content {
    width: 100%;
    height: 100%;

    display: flex;

    align-items: center;
    flex-direction: column;

    box-sizing: border-box;

    padding: 80px;

    padding: 36px;

    h2 {
        text-align: center;

        font-size: 30px;
        font-weight: 400;

        margin-bottom: 51px;
    }

    @include desktop {
        width: 50%;

        justify-content: center;

    }
}

.authentication__bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: unset;

    button {
        width: 100%;

        margin-top: 30px;
    }

    #forgot-password {
        margin-bottom: 33px;
    }

    @media (min-width: 1250px) {
        flex-direction: column-reverse;
        align-items: unset;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button {
            width: 276px;
            margin-top: 0px;
        }
    }
}


