.grant-cards {
    display: grid;
    grid-gap: 20px;

    grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
}

.grant-card {
    border-radius: 20px;

    background-color: white;

    padding: 20px;
    box-sizing: border-box;
}

.grant-card__top {
    display: flex;
    justify-content: space-between;

    p {
        font-size: 12px;
    }
}

.grant-card__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 13px ;

    word-break: break-all;

    h4 {
        font-size: 16px;
        font-weight: 400;
    }

    p {
        font-size: 12px;
        color: #354859;
    }
}

.grant-card__content {
    margin-top: 25px;

    p {
        font-size: 12px;
        color: #61707F;
    }
}

.grant-card__buttons {
    width: 100%;

    margin-top: 20px;

    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 20px;

    .utils__row {
        gap: 31px;
    }
}

.color {
    &--blue {
        color: $color-blue;
    }

    &--green {
        color: $color-green;
    }

    &--red {
        color: $color-red;
    }
}

.color-background {
    &--blue {
        background-color: $color-blue;
    }

    &--green {
        background-color: $color-green;
    }
}