.tab-bar__bar {
    width: 100%;

    background-color: #D2DBE2;

    justify-content: center;

    border-radius: 0px 0px 20px 20px;

    display: flex;
    align-items: center;

    gap: 10px;
    padding: 10px;

    box-sizing: border-box;
    flex-wrap: wrap;

    button {
        margin: 10px;
        height: 30px;
    }

    .pill {
        cursor: pointer;
    }

    @include tablet {
        height: 85px;
        flex-direction: row;
    }
}

.tab-bar__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab-bar__link {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -5px;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
