.mobile-section-handler__desktop {
    display: none;

    @include phone {
        display: grid;
    }
}

.layout {
    margin-top: 30px;
}

.layout__topbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 30px;

    padding-bottom: 30px;
    margin-bottom: 30px;

    border-bottom: 1px solid $border-color;

    @media (min-width: 1650px) {
        flex-direction: row;
        gap: 0px;
    }
}

.layout__middle {
    width: 100%;

    margin-top: 30px;
}

.layout__inner {
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        display: grid;
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.mobile-section-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    margin-bottom: 20px;

    @include phone {
        display: none;
    }
}

.mobile-section-buttons__button {
    border-radius: 20px;

    background-color: $color-blue;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 56px;

    cursor: pointer;
}

.layout__seventy-thirty {
    grid-template-columns: calc(70% - 35px) 30%;
    grid-gap: 35px;
}

.layout__sixty-fourty {
    grid-template-columns: calc(60% - 35px) 40%;
    grid-gap: 35px;
}

.layout__fifty-fifty {
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-gap: 35px;

    .layout__main-container {
        width: 100%;
    }
}

.layout__min-max {
    grid-template-columns: 1fr;

    .layout__main-container {
        width: 100%;
    }
}

.layout__main-container {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    border-radius: 20px;

}

.layout__inner-container {
    width: 100%;

    box-sizing: border-box;
    border-radius: 20px;


    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0px;
    }
}

.mobile-section-handler__desktop {
    display: none;

    &--toggled {
        display: none;
    }

    @include phone {
        display: block;
    }
}

.mobile-section-handler__mobile {
    display: none;
    overflow-y: auto;
    height: 100%;
    &--toggled {
        display: block;
    }
}

.mobile-section-handler__preview {
    display: none;

    &--toggled {
        display: block;
    }
}

.layout__outer-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 35px;

    @include tablet {
        grid-template-columns: 1fr 1fr;
    }

    @include desktop-large {
        display: flex;
        flex-direction: column;
    }
}

.mobile-section-handler__screen {
    background-color: white;

    &--toggled {
        display: block;

        width: calc(100% - 72px);
        height: 100vh;

        position: absolute;

        top: 0px;

        padding-top: 36px;

        @include phone {
            display: none;
        }
    }
}

.mobile-section-handler__screen__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 20px;
}

.mobile-section-handler__screen__close {
    text-decoration: underline;
    cursor: pointer;
}
