.appointment-cal {
    border: none !important;
    padding: 20px !important;
    border-radius: 20px !important;
    width: 390px !important;
    border: 1px solid #C6C7D1 !important;

    .react-calendar__navigation {
        height: initial;
    }

    .react-calendar__navigation__label__labelText {
        color: $color-grey;
    }

    .react-calendar__navigation__arrow {
        color: $color-blue;
    }
    .react-calendar__month-view__weekdays__weekday {
        text-transform: capitalize;
        font-weight: normal;
        text-decoration: none;
        color: $color-grey;


        abbr {
            text-decoration: none;
        }
    }


    .react-calendar__tile {
        background: #FFFFFF;
        border: 1px solid $color-light-grey;
        margin: 4px;
        border-radius: 10px;
        min-height: 50px;
        position: relative;

        &:hover {
            background: $color-light-grey;
        }

        &--active {
            background: $color-green !important;

            &:hover {
                background: $color-light-grey !important;
            }

            .appointment-cal {
                color: white !important;
            }
        }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        background: $color-light-grey;
        .appointment-cal__day {
            color: white !important;
        }
    }

}

.appointment-cal__day {
    position: absolute;
    color: $color-blue;
    font-size: 10px;
    top: 5px;
    left: 5px;
}

.appointment-cal__pip {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background: $color-green;
    position: absolute;
    left: 0px;
    bottom:-20px;
}
